import { type FC, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDebounceValue } from 'usehooks-ts';
import { PurpleIcon } from '@purple/icons';
import { ALLOWED_BULK_TEMPLATES } from '@purple/shared-utils';
import { AppFileDropZone, Button, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, FormControl, FormField, FormItem, FormLabel, FormMessage, MultiSelect, MultiSelectItem, Text, UploadedFilesPreview } from '@purple/ui';
import { useDistrictsListBasicInfo, useDownloadBulkTemplate, useUserSchools } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { BULK_DROPZONE_ALLOWED_FILE_TYPES, MAX_FILE_SIZE, type TBulkUploadSafRoutingRulesSchema } from './schema';

const DEFAULT_SEARCH_DELAY = 500;

type TUploadFormContentProps = {
  isReuploading: boolean;
};

export const UploadFormContent: FC<TUploadFormContentProps> = ({ isReuploading }) => {
  const { control, setValue, watch } = useFormContext<TBulkUploadSafRoutingRulesSchema>();

  const uploadedFile = watch('file');
  const selectedDistrict = watch('district');

  const [searchValue, setSearchValue] = useState<string>('');
  const [schoolsSearch, setSchoolsSearch] = useState<string>('');
  const [debouncedSearch] = useDebounceValue(searchValue, DEFAULT_SEARCH_DELAY);
  const [schoolsDebouncedSearch] = useDebounceValue(schoolsSearch, DEFAULT_SEARCH_DELAY);

  const { data: activeDistricts, isFetching } = useDistrictsListBasicInfo({
    search: debouncedSearch,
    limit: 50,
    status: 'published',
  });

  const { data: schools, isLoading: isSchoolsLoading } = useUserSchools({
    requestParameters: {
      district: selectedDistrict,
      search: schoolsDebouncedSearch,
    },
    enabled: selectedDistrict.length > 0,
  });

  const { mutate, isPending } = useDownloadBulkTemplate(ALLOWED_BULK_TEMPLATES.IMPORT_SAF_ROUTING_RULES);

  const districtsSelectOptions = useMemo(() => {
    return (
      activeDistricts?.results.map((district) => ({
        ...district,
        label: district.name,
        value: district.id,
      })) ?? []
    );
  }, [activeDistricts]);

  const schoolsSelectOptions = useMemo(() => {
    return (
      schools?.results.map((school) => ({
        ...school,
        label: school.name,
        value: school.id,
      })) ?? []
    );
  }, [schools]);

  const downloadTemplateHandler = useCallback(() => {
    mutate(ALLOWED_BULK_TEMPLATES.IMPORT_SAF_ROUTING_RULES, {
      onSuccess: (response) => {
        const link = document.createElement('a');
        link.href = response.file;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(response.file);
        showSuccessToast('System message', 'The template has been downloaded successfully to your default download location.');
      },
    });
  }, [mutate]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-4">
        <FormField
          control={control}
          name="district"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>District</FormLabel>
              <FormControl>
                <ComboBox modal>
                  <ComboBoxTrigger
                    isError={!!fieldState.error}
                    placeholder="Select district"
                    disabled={isReuploading}
                    selectedLabel={
                      districtsSelectOptions.find((option) => option.value.toString() === field.value.toString())?.label
                    }
                  />
                  <ComboBoxContent
                    loading={isFetching}
                    shouldFilter={false}
                    searchValue={searchValue}
                    searchPlaceholder="Search district..."
                    emptyContent="District not found."
                    onSearchChange={setSearchValue}
                  >
                    {activeDistricts?.results?.map(({ id, name }) => (
                      <ComboBoxItem
                        key={id}
                        value={id.toString()}
                        selected={field.value.toString() === id.toString()}
                        onSelect={(value) => {
                          field.onChange(value);
                          setValue('schools', []);
                        }}
                      >
                        {name}
                      </ComboBoxItem>
                    ))}
                  </ComboBoxContent>
                </ComboBox>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="schools"
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel required>School(s)</FormLabel>
              <FormControl>
                <MultiSelect
                  isError={!!fieldState.error}
                  selectedOptions={field.value}
                  modalPopover
                  placeholder="Select school(s)"
                  showSearch
                  searchValue={schoolsSearch}
                  searchPlaceholder="Search..."
                  shouldFilter={false}
                  disabled={!selectedDistrict}
                  loading={isSchoolsLoading}
                  onSearchChange={setSchoolsSearch}
                  onOptionChange={(_, selected) => field.onChange(selected)}
                >
                  {schoolsSelectOptions.map((school) => (
                    <MultiSelectItem
                      key={school.value}
                      value={school.value}
                      option={school}
                    />
                  ))}
                </MultiSelect>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-col items-center gap-4">
          <div className="flex flex-col items-center gap-2">
            <Text variant="size-16" type="body-600">Download Template</Text>
            <Text variant="size-14" type="body-400" className="text-grey-600">Download the template and effortlessly upload all your saf routing rules with just one click.</Text>
          </div>
          <Button type="button" iconLeft={isPending ? <PurpleIcon name="loader" className="animate-spin" /> : <PurpleIcon name="download" />} variant="tertiary" size="small" onClick={downloadTemplateHandler}>
            {isPending ? 'Downloading...' : 'Download Template'}
          </Button>
        </div>
        <div>
          {uploadedFile && uploadedFile.length > 0
            ? (
                <UploadedFilesPreview
                  fileName={uploadedFile[0]?.name}
                  fileType={uploadedFile[0]?.type}
                  onRemove={() => setValue('file', [])}
                />
              )
            : (
                <FormField
                  control={control}
                  name="file"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <AppFileDropZone
                          value={field.value}
                          onValueChange={(files) => files && setValue('file', files, { shouldValidate: true })}
                          accept={BULK_DROPZONE_ALLOWED_FILE_TYPES}
                          maxSize={MAX_FILE_SIZE}
                          description="The uploaded file should be in XLSX or CSV format and less than 10MB."
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
        </div>
      </div>
    </div>
  );
};
