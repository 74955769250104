import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { DISTRICT_STATUS, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Button, SearchInput, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions, DistrictFilterComboBox, SelectAllEntitiesRow, SelectedItemsRow, TransferSafModal } from '~/components';
import { ModalType, SAFS_PRIORITY_OPTIONS, SAFS_STATUS_OPTIONS } from '~/constants';
import { useDataTable, useModal, useSelectedItems } from '~/hooks';
import { type TAdminSafItem, useSafBulkDelete, useSafListMutation, useSafsList, useSafsListFilters } from '~/services';
import { PrintDropdown } from './components';
import { DISTRICT_QUERY_NAME, OWNER_QUERY_NAME, PRIORITY_QUERY_NAME, SAFS_TOTAL_QUERY_NAME, SCHOOL_QUERY_NAME, STATUS_QUERY_NAME } from './constants';
import { useAllSafsColumns } from './useAllSafsColumns';

export const AllSafs = () => {
  const [searchParameters] = useSearchParams();

  const [clickableSaf, setClickableSaf] = useState<TAdminSafItem | null>(null);

  const { openModal: openDeleteSafModal, closeModal: closeDeleteSafModal } = useModal(
    ModalType.SAF_DELETE,
  );

  const { openModal: openDeleteSafBulkModal, closeModal: closeDeleteSafBulkModal } = useModal(
    ModalType.SAF_DELETE_BULK,
  );

  const { openModal: openTransferSafModal } = useModal(
    ModalType.TRANSFER_SAF,
  );

  const { mutate: bulkDelete, isPending: isSafDeleting } = useSafBulkDelete();

  const { query: priorityQuery } = useQueryParameter({ queryName: PRIORITY_QUERY_NAME, resetOffset: true });
  const { query: statusQuery } = useQueryParameter({ queryName: STATUS_QUERY_NAME, resetOffset: true });
  const { query: districtQuery, onQueryChange: onDistrictQueryChange, onClearQuery: onDistrictQueryClear } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });
  const { onQueryChange: onSafsTotalQueryChange, onClearQuery: onSafsTotalQueryClear } = useQueryParameter({ queryName: SAFS_TOTAL_QUERY_NAME, resetOffset: false });
  const { query: schoolQuery } = useQueryParameter({ queryName: SCHOOL_QUERY_NAME, resetOffset: true });
  const { query: ownerQuery } = useQueryParameter({ queryName: OWNER_QUERY_NAME, resetOffset: true });

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const requestParameters = useMemo(() => ({
    ...(priorityQuery && { priority: priorityQuery }),
    ...(statusQuery && { status: statusQuery }),
    ...(districtQuery && { district: districtQuery }),
    ...(schoolQuery && { school: schoolQuery }),
    ...(ownerQuery && { current_owner: ownerQuery }),
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
  }), [debounceSearch, districtQuery, ownerQuery, priorityQuery, schoolQuery, searchParameters, statusQuery]);

  const { data: safsData, isLoading: isSafsLoading } = useSafsList({ requestParameters });

  const safs = useMemo(() => safsData?.results ?? [], [safsData?.results]);

  const { data: safFilters, isLoading: isSafFiltersLoading } = useSafsListFilters();

  useEffect(() => {
    if (safsData) {
      onSafsTotalQueryChange(safsData.count.toString());
    } else {
      onSafsTotalQueryClear();
    }
  }, [onSafsTotalQueryChange, onSafsTotalQueryClear, safsData]);

  const { mutate: getAllSafs, isPending: isGetAllSafsPending } = useSafListMutation();

  const {
    selectedItems,
    onChangeItem,
    onReplaceAllItems,
    onClearAllItems,
  } = useSelectedItems();

  const allItemsOnPage = useMemo(() => {
    return (
      safsData?.results?.map((item) => ({
        id: item.id,
        name: item.saf_number,
      })) || []
    );
  }, [safsData]);

  // TODO: It will be implemented in the future sprints
  const singleTransferClickHandler = (item: TAdminSafItem) => {
    console.log('Transfer SAF', item); // eslint-disable-line no-console
  };

  const singleDeleteClickHandler = (item: TAdminSafItem) => {
    setClickableSaf(item);
    openDeleteSafModal();
  };

  const confirmDeleteSafHandler = () => {
    if (clickableSaf) {
      bulkDelete({ safIds: [clickableSaf.id] }, {
        onSuccess: () => {
          closeDeleteSafModal();
        },
      });
    }
  };

  const confirmBulkDeleteSafHandler = () => {
    const safIds = selectedItems.map((item) => item.id);
    if (safIds.length) {
      bulkDelete({ safIds }, {
        onSuccess: () => {
          closeDeleteSafBulkModal();
        },
      });
    }
  };

  const { table } = useDataTable({
    columns: useAllSafsColumns({
      onDelete: singleDeleteClickHandler,
      onTransfer: singleTransferClickHandler,
    }),
    data: safs,
    rowCount: safsData?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
      },
    },
    getRowId: (originalRow) => originalRow.id,
    onSelectionChange: (selectedRows) => {
      const newSelectedSafs = Object.keys(selectedRows).filter((id) => {
        const existInSafs = safs.find((saf) => saf.id === id);
        const existInSelectedItems = selectedItems.find((item) => item.id === id);
        return existInSafs || existInSelectedItems;
      }).map((id) => {
        const name = safs.find((saf) => saf.id === id)?.saf_number || selectedItems.find((item) => item.id === id)?.name || '';
        const districtId = safs.find((saf) => saf.id === id)?.district.id || '';

        return {
          id,
          name,
          additionalField: districtId,
        };
      });

      onReplaceAllItems(newSelectedSafs);
    },
  });

  // NOTE: update table selection in case if selectedItems were changed
  useEffect(() => {
    const prevSelectionState = table.getState().rowSelection;
    const prevSelectionIds = Object.keys(prevSelectionState);
    const prevSelectionStateLength = prevSelectionIds.length;

    const wasChanged = prevSelectionStateLength !== selectedItems.length || prevSelectionIds.some((id) => !selectedItems.find((item) => item.id === id));

    if (!wasChanged) {
      return;
    }

    const selectedRows = selectedItems.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: true,
      };
    }, {});
    table.setRowSelection(selectedRows);
  }, [selectedItems, table]);

  const filterConfig = useMemo(
    () => {
      const fetchedFilters = safFilters
        ? {
            [SCHOOL_QUERY_NAME]: safFilters.school.map(({ id, name }) => ({
              label: name,
              value: id,
            })),
            [OWNER_QUERY_NAME]: safFilters.current_owner.map(({ id, full_name }) => ({
              label: full_name,
              value: id,
            })),
          }
        : null;

      const filters = {
        [PRIORITY_QUERY_NAME]: Object.values(SAFS_PRIORITY_OPTIONS).map(({ label, value }) => ({
          label,
          value,
        })),
        [STATUS_QUERY_NAME]: Object.values(SAFS_STATUS_OPTIONS).map(({ label, value }) => ({
          label,
          value,
        })),
        ...(Boolean(fetchedFilters) && { ...fetchedFilters }),
      };

      return convertToFilterConfig(filters, { snakeCaseValue: false });
    },
    [safFilters],
  );

  const selectAllFoundHandler = () => {
    getAllSafs({
      ...(priorityQuery && { priority: priorityQuery }),
      ...(statusQuery && { status: statusQuery }),
      ...(districtQuery && { district: districtQuery }),
      search: debounceSearch,
      limit: safsData?.count || 0,
      offset: 0,
      ordering: searchParameters.get(SORT_QUERY_NAME),
    }, {
      onSuccess: (allSafsResponse) => {
        const newItems
          = allSafsResponse.results.map((saf) => {
            return {
              id: saf.id,
              name: saf.saf_number,
              additionalField: saf.district.id,
            };
          }) || [];
        onReplaceAllItems(newItems);
      },
    });
  };

  const clearAllSelectedItemsHandler = () => {
    onClearAllItems();
    table.setRowSelection({});
  };

  const [canTransferSaf, transferSafTooltipMessage] = useMemo(() => {
    if (!selectedItems.length) {
      return [false, 'Please select at least one SAF to transfer.'];
    }

    const firstSelectedItem = selectedItems[0];
    const isSameDistrict = selectedItems.every((item) => item.additionalField === firstSelectedItem?.additionalField && item.additionalField);

    if (!isSameDistrict) {
      return [false, 'You can transfer SAFs only within the same district.'];
    }

    return [true, 'Click to transfer selected SAFs.'];
  }, [selectedItems]);

  return (
    <div className="flex flex-col">
      <DataTable
        table={table}
        loading={isSafsLoading}
      >
        <div className="flex flex-col gap-1">
          <div className="flex w-full items-center justify-between gap-4 p-4">
            <div className="flex items-center gap-4">
              <div className="min-w-[200px]">
                <DistrictFilterComboBox value={districtQuery || ''} onChange={onDistrictQueryChange} onClear={onDistrictQueryClear} status={DISTRICT_STATUS.PUBLISHED} />
              </div>
              <AppFilters config={filterConfig} loading={isSafFiltersLoading} />
              <SearchInput
                value={search}
                placeholder="Search"
                className="max-w-[300px]"
                onChange={onSearchChange}
                onClear={onClearSearch}
              />
            </div>
            <div className="flex flex-row gap-4">
              <Button
                type="button"
                variant="destructive_secondary"
                size="default"
                disabled={!selectedItems.length}
                onClick={openDeleteSafBulkModal}
              >
                Delete
              </Button>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div>
                    <Button
                      type="button"
                      variant="secondary"
                      size="default"
                      disabled={!canTransferSaf}
                      onClick={openTransferSafModal}
                    >
                      Transfer SAF
                    </Button>
                  </div>
                </TooltipTrigger>
                <TooltipPortal>
                  <TooltipContent>{transferSafTooltipMessage}</TooltipContent>
                </TooltipPortal>
              </Tooltip>
              <PrintDropdown safRequestParameters={requestParameters} />
              <DataTableViewOptions table={table} />
            </div>
          </div>
          <div className="flex flex-col gap-4 px-4 pb-4">
            <SelectAllEntitiesRow totalCount={safsData?.count || 0} selectedItems={selectedItems} allItemsOnPage={allItemsOnPage} onSelectAll={selectAllFoundHandler} isSelectingAll={isGetAllSafsPending} entityName="Saf(s)" />
            <SelectedItemsRow
              selectedItems={selectedItems}
              onClearAll={clearAllSelectedItemsHandler}
              onClose={(newItem) => onChangeItem({
                ...newItem,
                additionalField: '',
              })}
            />
          </div>
        </div>
      </DataTable>
      <CallToActionModal
        modalName={ModalType.SAF_DELETE}
        modalTitle="Delete SAF"
        modalDescription="Are you sure you want to delete the selected SAF?"
        modalTextContent="Are you sure you want to delete the selected SAF?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={confirmDeleteSafHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isSafDeleting}
      />
      <CallToActionModal
        modalName={ModalType.SAF_DELETE_BULK}
        modalTitle="Delete SAFs"
        modalDescription="Are you sure you want to delete the selected SAFs?"
        modalTextContent="Are you sure you want to delete the selected SAFs?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={confirmBulkDeleteSafHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isSafDeleting}
      />
      <TransferSafModal safIds={selectedItems.map((item) => item.id)} />
    </div>
  );
};
