import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Heading, SearchInput, Separator } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { SAFS_PRIORITY_OPTIONS, SAFS_STATUS_OPTIONS } from '~/constants';
import { useDataTable } from '~/hooks';
import { useSafsList, useSafsListFilters } from '~/services/saf';
import { submittedSafsColumns } from './submittedSafsColumns';

const PRIORITY_QUERY_NAME = 'priority';
const STATUS_QUERY_NAME = 'status';
const OWNER_QUERY_NAME = 'owner';

const SubmittedSafsTab = () => {
  const { userId } = useParams();

  const [searchParameters] = useSearchParams();

  const { query: priorityQuery } = useQueryParameter({ queryName: PRIORITY_QUERY_NAME, resetOffset: true });
  const { query: statusQuery } = useQueryParameter({ queryName: STATUS_QUERY_NAME, resetOffset: true });
  const { query: ownerQuery } = useQueryParameter({ queryName: OWNER_QUERY_NAME, resetOffset: true });

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: safsData, isLoading: isSafsLoading } = useSafsList({
    requestParameters: {
      ...(priorityQuery && { priority: priorityQuery }),
      ...(statusQuery && { status: statusQuery }),
      ...(ownerQuery && { current_owner: ownerQuery }),
      user: userId,
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
    },
    enabled: !!userId,
  });

  const safs = useMemo(() => safsData?.results ?? [], [safsData?.results]);

  const { data: safFilters, isLoading: isSafFiltersLoading } = useSafsListFilters({ user: userId });

  const { table } = useDataTable({
    columns: submittedSafsColumns,
    data: safs,
    rowCount: safsData?.count,
  });

  const filterConfig = useMemo(
    () => {
      const fetchedFilters = safFilters
        ? {
            [OWNER_QUERY_NAME]: safFilters.current_owner.map(({ id, full_name }) => ({
              label: full_name,
              value: id,
            })),
          }
        : null;

      const filters = {
        [PRIORITY_QUERY_NAME]: Object.values(SAFS_PRIORITY_OPTIONS).map(({ label, value }) => ({
          label,
          value,
        })),
        [STATUS_QUERY_NAME]: Object.values(SAFS_STATUS_OPTIONS).map(({ label, value }) => ({
          label,
          value,
        })),
        ...(Boolean(fetchedFilters) && { ...fetchedFilters }),
      };

      return convertToFilterConfig(filters, { snakeCaseValue: false });
    },
    [safFilters],
  );

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between px-6 py-4">
        <Heading variant="size-18" type="heading-600" className="text-grey-950">Submitted SAFs</Heading>
      </div>
      <Separator className="bg-grey-200" />
      <DataTable
        table={table}
        loading={isSafsLoading}
      >
        <div className="flex flex-col gap-1">
          <div className="flex w-full items-center justify-between gap-4 p-4">
            <div className="flex items-center gap-4">
              <AppFilters config={filterConfig} loading={isSafFiltersLoading} />
              <SearchInput
                value={search}
                placeholder="Search"
                className="max-w-[300px]"
                onChange={onSearchChange}
                onClear={onClearSearch}
              />
            </div>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </DataTable>
    </div>
  );
};

export { SubmittedSafsTab };
