import { useMemo, useState } from 'react';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { Button, Heading, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, SearchInput } from '@purple/ui';
import { CallToActionModal } from '~/components';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useContactsPriorityLists, useDeleteContactsPriorityList } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ManagePriorityListDialog, SendEmailDialog } from '../../components';
import { LIST_TYPE_OPTIONS, PriorityListQueryName, PriorityListViewType } from './constants';
import { PriorityListCard } from './PriorityListCard';
import { PriorityListEmpty } from './PriorityListEmpty';
import { PriorityListSkeleton } from './PriorityListSkeleton';
import { SharePriorityListDialog } from './SharePriorityListDialog';
import type { TContactPriorityListItem } from '~/services';
import type { TPriorityListViewType } from './constants';

export const ListView: React.FC = () => {
  const [selectedPriorityList, setSelectedPriorityList] = useState<TContactPriorityListItem | null>(null);

  const { onQueryChange: onViewChange } = useQueryParameter<TPriorityListViewType>({
    queryName: PriorityListQueryName.VIEW,
  });
  const { query: listType, onQueryChange: onListTypeChange, onClearQuery: onListTypeClear } = useQueryParameter({
    queryName: PriorityListQueryName.LIST_TYPE,
  });

  const { toggleModal: toggleDeleteModal } = useModal(ModalType.DELETE_CONTACTS_PRIORITY_LIST);
  const { toggleModal: toggleManageModal } = useModal(ModalType.MANAGE_CONTACTS_PRIORITY_LIST);
  const { toggleModal: toggleShareModal } = useModal(ModalType.SHARE_CONTACTS_PRIORITY_LIST);
  const { toggleModal: toggleEmailModal } = useModal(ModalType.SEND_EMAIL_TO_CONTACTS);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useContactsPriorityLists({
    search: debounceSearch,
    list_type: listType,
  }, { retry: (failCount) => failCount < 3 });
  const { mutate: deletePriorityList, isPending: isDeletePending } = useDeleteContactsPriorityList();

  const priorityLists = useMemo(() => data?.pages.map((page) => page.results).flat() || [], [data]);
  const isEmpty = useMemo(() => priorityLists.length === 0, [priorityLists]);

  const listDeleteClickHandler = (list: TContactPriorityListItem) => {
    setSelectedPriorityList(list);
    toggleDeleteModal(true);
  };

  const deleteSubmitHandler = () => {
    if (!selectedPriorityList) {
      return showErrorToast('System Error', 'Selected priority list is not found.');
    }

    if (!selectedPriorityList.is_owner) {
      toggleDeleteModal(false);
      return showErrorToast('Access Denied', 'This is a shared priority list. If you need to delete this priority list, please reach out to the owner. If you just want to hide it, please filter this list by “owned by me”.');
    }

    deletePriorityList(selectedPriorityList.id, {
      onSuccess: () => {
        toggleDeleteModal(false);
        setSelectedPriorityList(null);
      },
    });
  };

  const listEditClickHandler = (list: TContactPriorityListItem) => {
    setSelectedPriorityList(list);
    toggleManageModal(true);
  };

  const createListClickHandler = () => {
    setSelectedPriorityList(null);
    toggleManageModal(true);
  };

  const shareListClickHandler = (list: TContactPriorityListItem) => {
    setSelectedPriorityList(list);
    toggleShareModal(true);
  };

  const sendEmailClickHandler = (list: TContactPriorityListItem) => {
    setSelectedPriorityList(list);
    toggleEmailModal(true);
  };

  const viewAllClickHandler = (list: TContactPriorityListItem) => {
    onViewChange(PriorityListViewType.DETAILS, {
      onSuccess: (urlParameters) => {
        urlParameters.set(PriorityListQueryName.ID, list.id);
      },
    });
  };

  return (
    <div className="flex w-full flex-col">
      <Heading tag="h2" type="heading-600" variant="size-18" className="border-b border-grey-200 px-4 py-5 text-grey-title">
        My Priority Lists
      </Heading>
      <div className="flex w-full flex-col gap-6 p-4 pb-8">
        <div className="item-center flex gap-4">
          <RadixSelect value={listType ?? ''} onValueChange={onListTypeChange}>
            <RadixSelectTrigger hasClearButton={!!listType} className="gap-6" onClearCallback={onListTypeClear}>
              <RadixSelectValue placeholder="List Type" />
            </RadixSelectTrigger>
            <RadixSelectContent className="min-w-44">
              {LIST_TYPE_OPTIONS.map(({ label, value }) => (
                <RadixSelectItem key={value} value={value}>
                  {label}
                </RadixSelectItem>
              ))}
            </RadixSelectContent>
          </RadixSelect>
          <SearchInput
            value={search}
            placeholder="Search"
            className="max-w-[300px]"
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
        </div>
        {isLoading && !isFetchingNextPage && (
          <PriorityListSkeleton />
        )}
        {!isEmpty && (
          <ul className="grid w-full grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
            {priorityLists.map((item) => (
              <PriorityListCard
                key={item.id}
                item={item}
                onDelete={listDeleteClickHandler}
                onEdit={listEditClickHandler}
                onShare={shareListClickHandler}
                onViewAll={viewAllClickHandler}
                onSendEmail={sendEmailClickHandler}
              />
            ))}
          </ul>
        )}
        {isEmpty && !isFetching && (
          <PriorityListEmpty onCreate={createListClickHandler} />
        )}
        {hasNextPage && (
          <div className="flex w-full items-center justify-center">
            <Button
              type="button"
              variant="tertiary"
              size="small"
              isLoading={isFetchingNextPage}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage}
            >
              {isFetchingNextPage ? 'Loading More...' : 'View More'}
            </Button>
          </div>
        )}
      </div>
      <ManagePriorityListDialog priorityList={selectedPriorityList} />
      <SharePriorityListDialog priorityList={selectedPriorityList} />
      <SendEmailDialog priorityListId={selectedPriorityList?.id} />
      <CallToActionModal
        modalName={ModalType.DELETE_CONTACTS_PRIORITY_LIST}
        modalTitle="Delete Priority List"
        modalDescription="By deleting this priority list, it will be removed from the system and can't be recovered."
        modalTextContent="Are you sure you want to delete this priority list?"
        primaryButtonText={isDeletePending ? 'Deleting...' : 'Delete'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteSubmitHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
      />
    </div>
  );
};
