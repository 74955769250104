import { type FC, memo } from 'react';
import { Message } from '@purple/ui';
import { DataTable } from '~/components/DataTable';
import { useDataTable } from '~/hooks';
import { fileErrorsColumns } from './fileErrorsColumns';
import type { TBulkUploadResponse } from '@purple/shared-types';
import type { TUserBulkFileErrorResponse } from '~/services';

type TUploadFileStatisticProps = {
  fileStatisticData: TBulkUploadResponse<TUserBulkFileErrorResponse> | null;
};

export const UploadFileStatistic: FC<TUploadFileStatisticProps> = memo(({ fileStatisticData }) => {
  const { table } = useDataTable({
    columns: fileErrorsColumns,
    data: fileStatisticData?.fields ?? [],
    pageCount: 1,
    initialState: {
      columnPinning: {
        left: ['row'],
      },
    },
  });

  const successfulUploadedUsers = fileStatisticData?.success_count ?? 0;
  const skippedUsers = fileStatisticData?.skipped_count ?? 0;
  const failedUsers = fileStatisticData?.errors_count ?? 0;

  return (
    <div className="flex flex-col gap-6 p-6">
      <div className="flex flex-col gap-4">
        <Message variant="success" className="px-3 py-2 text-sm">
          <span className="font-medium">
            {successfulUploadedUsers}
          </span>
          {' '}
          users successfully invited
        </Message>
        {skippedUsers > 0 && (
          <Message variant="warning" className="px-3 py-2 text-sm">
            <span className="font-medium">{skippedUsers}</span>
            {' '}
            users already exist in the system and will not be added to avoid duplicates
          </Message>
        ) }
        {failedUsers > 0 && (
          <Message variant="error" className="px-3 py-2 text-sm">
            <span className="font-medium">{failedUsers}</span>
            {' '}
            rows contain errors
          </Message>
        )}
      </div>
      <DataTable
        table={table}
        hidePagination
        className="max-h-[450px]"
        emptyState={{
          title: 'No errors found',
          message: 'No errors found in the uploaded file',
          iconName: 'check-circle',
        }}
      />
    </div>
  );
});
