import { useEffect, useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Button, SearchInput, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTable, DataTableViewOptions, DistrictMultiSelect, SchoolMultiSelect } from '~/components';
import { AdminRoutes, SAFS_PRIORITY_OPTIONS } from '~/constants';
import { useDataTable } from '~/hooks';
import { DISTRICT_STATUSES, useSafListExportMutation, useSafsList } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { safsColumns } from './safsColumns';

const DAYS_COUNT = 2;

const DISTRICT_QUERY_NAME = 'district';
const SCHOOL_QUERY_NAME = 'school';
const PRIORITY_QUERY_NAME = 'priority';

const SafPrioritized = () => {
  const [searchParameters] = useSearchParams();

  const { query: priorityQuery } = useQueryParameter({ queryName: PRIORITY_QUERY_NAME, resetOffset: true });

  const { query: districtQuery, onQueryChange: onDistrictQueryChange } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const { query: schoolsQuery, onQueryChange: onSchoolsQueryChange, onClearQuery: onSchoolsQueryClear } = useQueryParameter({ queryName: SCHOOL_QUERY_NAME, resetOffset: true });

  useEffect(() => {
    if (!districtQuery) {
      onSchoolsQueryClear();
    }
  }, [districtQuery, onSchoolsQueryClear]);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { data: safsData, isLoading: isSafsLoading } = useSafsList({
    requestParameters: {
      ...(priorityQuery && { priority: priorityQuery }),
      ...(districtQuery && { district: districtQuery }),
      ...(schoolsQuery && { school: schoolsQuery }),
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      time_until_prioritized_days__lte: DAYS_COUNT,
    },
  });

  const { mutate: exportSafs, isPending: isExportingSafs } = useSafListExportMutation();

  const safs = useMemo(() => safsData?.results ?? [], [safsData?.results]);

  const { table } = useDataTable({
    columns: safsColumns,
    data: safs,
    rowCount: safsData?.count,
  });

  const selectedSchools = useMemo(() => {
    const array = schoolsQuery?.split(',') || [];
    return array;
  }, [schoolsQuery]);

  const selectedDistricts = useMemo(() => {
    const array = districtQuery?.split(',') || [];
    return array;
  }, [districtQuery]);

  const exportClickHandler = () => {
    if (safsData) {
      exportSafs({
        ...(priorityQuery && { priority: priorityQuery }),
        ...(districtQuery && { district: districtQuery }),
        ...(schoolsQuery && { school: schoolsQuery }),
        search: debounceSearch,
        limit: safsData.count,
        offset: searchParameters.get(OFFSET_QUERY_NAME),
        ordering: searchParameters.get(SORT_QUERY_NAME),
        time_until_prioritized_days__lte: DAYS_COUNT,
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
        },
      });
    }
  };

  const filterConfig = useMemo(
    () => {
      const filters = {
        [PRIORITY_QUERY_NAME]: Object.values(SAFS_PRIORITY_OPTIONS).map(({ label, value }) => ({
          label,
          value,
        })),
      };

      return convertToFilterConfig(filters, { snakeCaseValue: false });
    },
    [],
  );

  const districtChangeHandler = (newValue: string[]) => {
    onDistrictQueryChange(newValue.join(','));
  };

  const schoolsChangeHandler = (newValue: string[]) => {
    onSchoolsQueryChange(newValue.join(','));
  };

  return (
    <div className="flex w-full flex-1 flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="text-brand-blue-700 size-4 shrink-0" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Home.Root.path}>
          Back to Home Page
        </Link>
      </Button>
      <DataTable table={table} loading={isSafsLoading} className="border-grey-200 shadow-custom-heavy rounded-lg border bg-white">
        <div className="flex w-full flex-col gap-4 p-4 pt-6">
          <Text variant="size-16" type="body-600" className="text-grey-title">SAFs Prioritized (Under 48 hrs)</Text>
          <div className="flex w-full items-center justify-between gap-4">
            <div className="flex w-full items-center gap-4">
              <div className="min-w-[200px] max-w-[200px]">
                <SearchInput
                  value={search}
                  placeholder="Search"
                  className="max-w-[300px]"
                  onChange={onSearchChange}
                  onClear={onClearSearch}
                />
              </div>
              <div className="min-w-[200px]">
                <DistrictMultiSelect values={selectedDistricts || ''} onChange={districtChangeHandler} placeholder="Select district" status={DISTRICT_STATUSES.PUBLISHED} />
              </div>
              <div className="min-w-fit">
                <SchoolMultiSelect values={selectedSchools} onChange={schoolsChangeHandler} onClear={onSchoolsQueryClear} districtId={districtQuery} isDistrictRequired />
              </div>
              <AppFilters config={filterConfig} />
            </div>

            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Button type="button" variant="primary" size="icon_40" iconLeft={<PurpleIcon name="download" />} onClick={exportClickHandler} isLoading={isExportingSafs}>Export</Button>
                </div>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>Export</TooltipContent>
              </TooltipPortal>
            </Tooltip>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </DataTable>
    </div>
  );
};

export { SafPrioritized };
