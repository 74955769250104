import type { ValueOf } from '../utils';

export const ContactType = {
  PARENT_GUARDIAN: 'parent_guardian',
  SCHOOL_STAFF: 'school_staff',
  DISTRICT_STAFF: 'district_staff',
  GENERAL: 'general',
} as const;
export type TContactType = ValueOf<typeof ContactType>;
export const ReadableContactType = {
  [ContactType.PARENT_GUARDIAN]: 'Parent/Guardian',
  [ContactType.SCHOOL_STAFF]: 'School Staff',
  [ContactType.DISTRICT_STAFF]: 'District Staff',
  [ContactType.GENERAL]: 'General',
} as const;

export type TContact = {
  id: string;
  email: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  full_name: string;
  phone: string;
  user: string;
  contact_type: TContactType;
  district: string;
  created_at: string;
  updated_at: string;
};

export type TContactPriorityList = {
  id: string;
  name: string;
  description: string;
  contacts: string[];
  shared_with: string[];
  district: string;
  archived_at?: string | null;
  created_at: string;
  updated_at: string;
  is_owner: boolean;
  total_contacts: number;
};
