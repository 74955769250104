import { useCallback, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME } from '@purple/shared-types';
import { Button, SearchInput } from '@purple/ui';
import { AddContactStudentsModal, CallToActionModal, DataTable } from '~/components';
import { ModalType } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { CONTACTS_QUERY_KEYS, STUDENTS_QUERY_KEYS, useDeleteStudentGuardian, useStudents } from '~/services';
import { showErrorToast, showSuccessToast } from '~/utils/toasts';
import { ContactTabContainer } from '../../components';
import { connectedStudentsColumns } from './connectedStudentsColumns';
import type { TContactDetailItem } from '~/services';

export const ConnectedStudents = () => {
  const { contactId } = useParams();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const [selectedStudentId, setSelectedStudentId] = useState<string | null>(null);

  const { openModal } = useModal(ModalType.ADD_CONTACT_STUDENTS);
  const { openModal: openDeleteStudentGuardianModal, closeModal: closeDeleteStudentGuardianModal } = useModal(ModalType.DELETE_STUDENT_GUARDIAN);

  const { onClearSearch, onSearchChange, search, debounceSearch } = useSearch();

  const contactDetails = queryClient.getQueryData<TContactDetailItem>([CONTACTS_QUERY_KEYS.GET_CONTACT_DETAILS, contactId]);

  const { data: studentList, isLoading } = useStudents({
    parameters: {
      guardian: contactId,
      search: debounceSearch,
      limit: searchParams.get(LIMIT_QUERY_NAME),
      offset: searchParams.get(OFFSET_QUERY_NAME),
    },
  });

  const { mutate: deleteStudentGuardian, isPending } = useDeleteStudentGuardian();

  const { table } = useDataTable({
    columns: connectedStudentsColumns,
    data: studentList?.results || [],
    rowCount: studentList?.count || 0,
    getRowId: (row) => row.id,
    meta: {
      studentsCount: studentList?.count || 0,
      setSelectedStudentId: (id: string) => {
        setSelectedStudentId(id);
        openDeleteStudentGuardianModal();
      },
    },
  });

  const deleteStudentHandler = useCallback(() => {
    if (!selectedStudentId) {
      showErrorToast('System error', `Can not delete student with id: ${selectedStudentId}`);
      return;
    }
    deleteStudentGuardian(selectedStudentId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [STUDENTS_QUERY_KEYS.GET_STUDENTS_LIST, {
          guardian: contactId,
          search: debounceSearch,
          limit: searchParams.get(LIMIT_QUERY_NAME),
          offset: searchParams.get(OFFSET_QUERY_NAME),
        }] });
        showSuccessToast('System message', 'Student has been removed from the guardian');
        setSelectedStudentId(null);
        closeDeleteStudentGuardianModal();
      },
    });
  }, [deleteStudentGuardian, queryClient, selectedStudentId, contactId, debounceSearch, searchParams, closeDeleteStudentGuardianModal]);

  return (
    <>
      <ContactTabContainer
        title="Connected Students"
        className="p-0"
        actions={(
          <Button variant="secondary" iconLeft={<PurpleIcon name="plus" />} onClick={openModal}>
            Add Students
          </Button>
        )}
      >
        <DataTable table={table} loading={isLoading}>
          <div className="p-4">
            <SearchInput
              value={search}
              onChange={onSearchChange}
              onClear={onClearSearch}
              placeholder="Search..."
              className="max-w-[300px]"
            />
          </div>
        </DataTable>
      </ContactTabContainer>
      <AddContactStudentsModal distractId={contactDetails?.district?.id.toString() ?? ''} contactId={contactId ?? ''} />
      <CallToActionModal
        modalName={ModalType.DELETE_STUDENT_GUARDIAN}
        modalTitle="Remove Student"
        modalDescription="Modal window for removing student from the guardian"
        showModalDescription={false}
        modalTextContent="Are you sure you want to remove this student from the guardian?"
        primaryButtonText="Remove"
        primaryButtonVariant="destructive_primary"
        onPrimaryButtonClick={deleteStudentHandler}
        isLoading={isPending}
      />
    </>
  );
};
