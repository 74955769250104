import { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import {
  DISTRICT_STATUS,
  DistrictUserStatus,
  LIMIT_QUERY_NAME,
  OFFSET_QUERY_NAME,
  SORT_QUERY_NAME,
} from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, Button, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions, SelectAllEntitiesRow, SelectedItemsRow } from '~/components';
import { AdminRoutes, ModalType } from '~/constants';
import { useDataTable, useModal, useSelectedItems } from '~/hooks';
import {
  useDistrict,
  useDistrictBulkDeactivateUsers,
  useDistrictUsers,
  useDistrictUsersFilterOptions,
  useDistrictUsersMutation,
  useUserDeactivateCheck,
} from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { DistrictTabContainer } from '../../DistrictTabContainer';
import { districtUserColumns } from './districtUserColumns';
import { DistrictUsersSkeleton } from './DistrictUsersSkeleton';
import type {
  TUsersDeactivateCheck,
} from '~/services';

const GRADE_QUERY_NAME = 'grade';
const ROLE_QUERY_NAME = 'role';

export const DistrictUsersTab: React.FC = () => {
  const { id } = useParams();
  const [searchParameters] = useSearchParams();
  const navigate = useNavigate();

  const { openModal: openDeactivateModal, closeModal: closeDeactivateModal } = useModal(
    ModalType.BULK_USERS_DEACTIVATE,
  );
  const { openModal: openDeactivateWithSafModal, closeModal: closeDeactivateWithSafModal } = useModal(
    ModalType.BULK_USERS_WITH_SAF_DEACTIVATE,
  );

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data: district, isFetching: isDistrictFetching } = useDistrict({ id: id as string });
  const { data: options, isFetching: isOptionsLoading } = useDistrictUsersFilterOptions({
    district: district?.id,
    enabled: !!district,
  });
  const { data, isFetching: isUsersFetching } = useDistrictUsers({
    district: district?.id,
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    grade: searchParameters.get(GRADE_QUERY_NAME),
    status: searchParameters.get('status'),
    role: searchParameters.get(ROLE_QUERY_NAME),
    enabled: !!district,
  });
  const { mutate: bulkUserDeactivate, isPending: isDeactivatePending } = useDistrictBulkDeactivateUsers();

  const { mutate: checkUsersDeactivation } = useUserDeactivateCheck();

  const { mutate: getAllUsers, isPending: isGetAllUsersPending } = useDistrictUsersMutation();

  const users = useMemo(() => data?.results ?? [], [data?.results]);

  const districtUsers = useMemo(() => data?.results ?? [], [data?.results]);
  const filters = useMemo(
    () =>
      options
        ? Object.entries(options).reduce(
            (accumulator, [key, value]) =>
              key === GRADE_QUERY_NAME || key === ROLE_QUERY_NAME ? { ...accumulator, [key]: value } : accumulator,
            {
              status: Object.values(DistrictUserStatus).map((status) => ({
                id: status,
                name: status,
              })),
            },
          )
        : null,
    [options],
  );
  const filterConfig = useMemo(
    () => (filters ? convertToFilterConfig(filters, { snakeCaseValue: false }) : { categories: [] }),
    [filters],
  );

  const {
    selectedItems,
    onChangeItem,
    onReplaceAllItems,
    onClearAllItems,
  } = useSelectedItems();

  const allItemsOnPage = useMemo(() => {
    return (
      data?.results?.map((item) => ({
        id: item.id,
        name: item.full_name,
      })) || []
    );
  }, [data]);

  const { table } = useDataTable({
    columns: districtUserColumns,
    data: districtUsers,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['select'],
      },
    },
    getRowId: (originalRow) => originalRow.id,
    onSelectionChange: (selectedRows) => {
      const newSelectedUsers = Object.keys(selectedRows).filter((id) => {
        const existInUsers = users.find((user) => user.id === id);
        const existInSelectedItems = selectedItems.find((item) => item.id === id);
        return existInUsers || existInSelectedItems;
      }).map((id) => {
        const name = users.find((user) => user.id === id)?.full_name || selectedItems.find((item) => item.id === id)?.name || '';

        return {
          id,
          name,
        };
      });

      onReplaceAllItems(newSelectedUsers);
    },
  });

  const deactivateActionClickHandler = (checkResponse: TUsersDeactivateCheck) => {
    const hasRoutingRuleUsers = Object.keys(checkResponse.routing_rule_users).length > 0;
    const hasOpenedSafUsers = checkResponse.opened_saf_users.length > 0;
    const hasValidUsers = checkResponse.valid_users.length > 0;

    if (hasRoutingRuleUsers) {
      const usersIds = Object.keys(checkResponse.routing_rule_users);
      const users = data?.results.filter((user) => usersIds.includes(user.id));

      if (users && users?.length > 0) {
        const schools = Object.values(checkResponse.routing_rule_users).join(', ');
        const errorText = `Users are a part of the SAF routing directory for the following schools: ${schools}. If you are sure you want to deactivate these users, they will need to be removed from SAF routing rules first.`;
        showErrorToast(errorText, 'Users error');
        return;
      }
    }

    if (hasOpenedSafUsers) {
      const users = data?.results.filter((user) => checkResponse.opened_saf_users.includes(user.id));
      if (users && users?.length > 0) {
        openDeactivateWithSafModal();
        return;
      }
    }

    if (hasValidUsers) {
      const users = data?.results.filter((user) => checkResponse.valid_users.includes(user.id));
      if (users && users?.length > 0) {
        openDeactivateModal();
      }
    }
  };

  const deactivateClickHandler = (event?: React.MouseEvent) => {
    event?.stopPropagation();

    const selectedIds = selectedItems.map((item) => item.id);

    checkUsersDeactivation({ usersIds: selectedIds }, {
      onSuccess: (response) => {
        deactivateActionClickHandler(response);
      },
    });
  };

  const deactivateUsersClickHandler = () => {
    const selectedIds = selectedItems.map((item) => item.id);
    bulkUserDeactivate(
      {
        users: selectedIds,
      },
      {
        onSuccess: () => {
          closeDeactivateModal();
          closeDeactivateWithSafModal();
        },
      },
    );
  };

  // NOTE: update table selection in case if selectedItems were changed
  useEffect(() => {
    const prevSelectionState = table.getState().rowSelection;
    const prevSelectionIds = Object.keys(prevSelectionState);
    const prevSelectionStateLength = prevSelectionIds.length;

    const wasChanged = prevSelectionStateLength !== selectedItems.length || prevSelectionIds.some((id) => !selectedItems.find((item) => item.id === id));

    if (!wasChanged) {
      return;
    }

    const selectedRows = selectedItems.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: true,
      };
    }, {});
    table.setRowSelection(selectedRows);
  }, [selectedItems, table]);

  const selectAllFoundHandler = () => {
    getAllUsers({
      district: district?.id,
      search: debounceSearch,
      limit: data?.count || 0,
      offset: 0,
      ordering: searchParameters.get(SORT_QUERY_NAME),
      grade: searchParameters.get('grade'),
      status: searchParameters.get('status'),
      role: searchParameters.get('role'),
    }, {
      onSuccess: (allUsers) => {
        const newItems
          = allUsers.results.map((user) => {
            return {
              id: user.id,
              name: user.full_name,
            };
          }) || [];
        onReplaceAllItems(newItems);
      },
    });
  };

  const clearAllSelectedItemsHandler = () => {
    onClearAllItems();
    table.setRowSelection({});
  };

  if (isDistrictFetching || !district) return <DistrictUsersSkeleton />;

  if (district.status === DISTRICT_STATUS.DRAFT) {
    navigate(AdminRoutes.App.Districts.DistrictSetUp.makePath({ queries: { districtId: district.id } }), {
      replace: true,
    });
  }

  return (
    <DistrictTabContainer title="District Leaders & Users" className="gap-4 p-0">
      <DataTable
        table={table}
        loading={isUsersFetching}
      >
        <div className="flex flex-col gap-1">
          <div className="flex w-full items-center justify-between gap-4 p-4">
            <div className="flex items-center gap-4">
              <AppFilters config={filterConfig} loading={isOptionsLoading} />
              <SearchInput
                value={search}
                placeholder="Search users"
                className="max-w-[300px]"
                onChange={onSearchChange}
                onClear={onClearSearch}
              />
            </div>
            <div className="flex items-center gap-4">
              {selectedItems.length > 0 && (
                <Button type="button" variant="destructive_secondary" onClick={deactivateClickHandler}>
                  Deactivate user(s)
                </Button>
              )}
              <DataTableViewOptions table={table} />
            </div>
          </div>

          <div className="flex flex-col gap-4 px-4 pb-4">
            <SelectAllEntitiesRow totalCount={data?.count || 0} selectedItems={selectedItems} allItemsOnPage={allItemsOnPage} onSelectAll={selectAllFoundHandler} isSelectingAll={isGetAllUsersPending} entityName="User(s)" />
            <SelectedItemsRow selectedItems={selectedItems} onClearAll={clearAllSelectedItemsHandler} onClose={onChangeItem} />
          </div>
        </div>
      </DataTable>
      <CallToActionModal
        modalName={ModalType.BULK_USERS_DEACTIVATE}
        modalTitle="Deactivate Users"
        modalDescription="By deactivating district users, they will no longer have access to the district."
        modalTextContent="Are you sure you want to deactivate the selected users?"
        primaryButtonText="Deactivate"
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deactivateUsersClickHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeactivatePending}
      />
      <CallToActionModal
        modalName={ModalType.BULK_USERS_WITH_SAF_DEACTIVATE}
        modalTitle="The user has open SAFs"
        modalDescription="Have you had a conversation with district leadership to confirm who will be stepping into the vacancy and whether any open SAFs will be re-routed, and how?"
        modalTextContent="Have you had a conversation with district leadership to confirm who will be stepping into the vacancy and whether any open SAFs will be re-routed, and how?"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        onPrimaryButtonClick={deactivateUsersClickHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeactivatePending}
      />
    </DistrictTabContainer>
  );
};
