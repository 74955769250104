import { useCallback, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, AppSelectedFiltersList, Button, Heading, SearchInput, Separator } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions } from '~/components';
import { AdminRoutes, ModalType } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useAddUserDetailsNegativePermission, USER_PERMISSION_TYPE, useRemoveUserDetailsNegativePermission, useRemoveUserDetailsPermission, useUserDetailsPermissions } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { usePermissionsFilterOptions } from './usePermissionsFilterOptions';
import { useUserPermissionsColumns } from './useUserPermissionsColumns';
import type { TUserPermissionItem } from '~/services';

export const PermissionsTab = () => {
  const { userId } = useParams();
  const [searchParameters] = useSearchParams();

  const { closeModal: closeDeletePermissionModal } = useModal(ModalType.DELETE_USER_PERMISSION);
  const { closeModal: closeRemoveNegativePermissionModal } = useModal(ModalType.REMOVE_NEGATIVE_PERMISSION);

  const [selectedPermission, setSelectedPermission] = useState<TUserPermissionItem | null>(null);

  const { filterConfig } = usePermissionsFilterOptions();

  const { onClearSearch, onSearchChange, search, debounceSearch } = useSearch();

  const { data, isLoading } = useUserDetailsPermissions({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    constructed_from: searchParameters.get('constructed_from'),
    userId: userId as string,
  });

  const { mutate: removeUserPermission, isPending: isPermissionRemoving } = useRemoveUserDetailsPermission();
  const { mutate: removeUserNegativePermission, isPending: isRemovingNegativePermission } = useRemoveUserDetailsNegativePermission();
  const { mutate: addUserNegativePermission, isPending: isNegativePermissionAdding } = useAddUserDetailsNegativePermission();

  const { columns } = useUserPermissionsColumns({
    onPermissionDelete: setSelectedPermission,
  });

  const { table } = useDataTable({
    columns,
    data: data?.results || [],
    rowCount: data?.count || 0,
    getRowId: (row, index) => `${row.id}-${index}`,
  });

  const removeUserPermissionHandler = useCallback(() => {
    if (!userId || !selectedPermission) {
      return showErrorToast('System message', `Unable to remove permission with provided ${userId} and ${selectedPermission?.id}`);
    }
    selectedPermission.constructed_from === USER_PERMISSION_TYPE.PERMISSION
      ? (
          removeUserPermission({
            userId,
            permissionId: selectedPermission.id,
          }, {
            onSuccess: () => {
              closeDeletePermissionModal();
            },
          })
        )
      : (
          addUserNegativePermission({
            userId,
            permissionId: selectedPermission.id,
          }, {
            onSuccess: () => {
              closeDeletePermissionModal();
            },
          })
        );
  }, [userId, selectedPermission, removeUserPermission, closeDeletePermissionModal, addUserNegativePermission]);

  const removeNegativePermissionHandler = useCallback(() => {
    if (!userId || !selectedPermission) {
      return showErrorToast('System message', `Unable to remove negative permission with provided ${userId} and ${selectedPermission?.id}`);
    }
    removeUserNegativePermission({
      userId,
      permissionId: selectedPermission.id,
    }, {
      onSuccess: () => {
        closeRemoveNegativePermissionModal();
      },
    });
  }, [userId, selectedPermission, removeUserNegativePermission, closeRemoveNegativePermissionModal]);

  const closeDeleteModalHandler = useCallback(() => {
    setSelectedPermission(null);
    closeDeletePermissionModal();
  }, [closeDeletePermissionModal]);

  const closeRemoveNegativePermissionModalHandler = useCallback(() => {
    setSelectedPermission(null);
    closeRemoveNegativePermissionModal();
  }, [closeRemoveNegativePermissionModal]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center justify-between px-6 py-5">
          <Heading variant="size-18" type="heading-600" className="text-grey-950">Permissions</Heading>
          <Button variant="secondary" iconLeft={<PurpleIcon name="plus" />} asChild>
            <Link to={AdminRoutes.App.Users.UserDetail.UserManagePermissions.makePath({ dynamicParameters: { id: userId as string } })} target="_blank">
              Manage Permissions
            </Link>
          </Button>
        </div>
        <Separator className="bg-grey-200" />
        <DataTable table={table} loading={isLoading}>
          <div className="flex flex-col gap-4 p-4">
            <div className="flex items-center justify-between gap-2">
              <div className="flex items-center gap-4">
                <AppFilters config={filterConfig} />
                <SearchInput
                  placeholder="Search..."
                  value={search}
                  onChange={onSearchChange}
                  onClear={onClearSearch}
                />
              </div>
              <DataTableViewOptions table={table} />
            </div>
            <AppSelectedFiltersList config={filterConfig} />
          </div>
        </DataTable>
      </div>
      <CallToActionModal
        modalName={ModalType.REMOVE_NEGATIVE_PERMISSION}
        modalTitle="Remove Negative Permission"
        modalDescription="Remove negative permission modal"
        modalTextContent={`Performing this action will remove "${selectedPermission?.name}" negative permission from the selected user. Are you sure you want to proceed?`}
        showModalDescription={false}
        onPrimaryButtonClick={removeNegativePermissionHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isRemovingNegativePermission}
        primaryButtonText="Yes, Remove"
        onSecondaryButtonClick={closeRemoveNegativePermissionModalHandler}
      />
      <CallToActionModal
        modalName={ModalType.DELETE_USER_PERMISSION}
        modalTitle="Delete Permission"
        modalDescription="Delete permission modal"
        modalTextContent={`Performing this action will remove "${selectedPermission?.name}" permission from the selected user. Are you sure you want to proceed?`}
        showModalDescription={false}
        isLoading={isPermissionRemoving || isNegativePermissionAdding}
        onPrimaryButtonClick={removeUserPermissionHandler}
        primaryButtonVariant="destructive_primary"
        primaryButtonText="Yes, Remove"
        onSecondaryButtonClick={closeDeleteModalHandler}
      />
    </>
  );
};
