import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { DISTRICT_STATUS, LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Button, NoDataAvailable, RadixSelect, RadixSelectContent, RadixSelectItem, RadixSelectTrigger, RadixSelectValue, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions, DistrictFilterComboBox } from '~/components';
import { ModalType } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useActivityTypes, useUpdateActivityType } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ACTIVITY_TYPE_STATUS_OPTIONS } from './helpers';
import { useActivityTypeColumns } from './useActivityTypeColumns';
import type { TActivityTypeItem } from '~/services';

export const FormLayoutsTab: React.FC = () => {
  const [searchParameters] = useSearchParams();

  const { query: selectedDistrict, onQueryChange, onClearQuery } = useQueryParameter({ queryName: 'district', resetOffset: true });
  const { query: statusQuery, onQueryChange: onStatusChange, onClearQuery: onStatusClear } = useQueryParameter({ queryName: 'status', resetOffset: true });

  const { openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal(ModalType.ACTIVITY_TYPE_STATUS_CHANGE);

  const [selectedActivityType, setSelectedActivityType] = useState<TActivityTypeItem | null>(null);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = useActivityTypes({
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    search: debounceSearch,
    district: selectedDistrict,
    is_active: statusQuery,
  }, {
    enabled: !!selectedDistrict,
  });
  const { mutate: updateActivityType, isPending: isUpdatePending } = useUpdateActivityType();

  const activities = useMemo(() => data?.results ?? [], [data?.results]);

  const activityTypeStatusChangeHandler = useCallback(() => {
    if (!selectedActivityType) {
      return showErrorToast('Activity type not found', 'Please select an activity type to change its status');
    }

    updateActivityType({
      id: selectedActivityType.id,
      is_active: !selectedActivityType.is_active,
    }, {
      onSuccess: () => {
        closeDeleteModal();
        setSelectedActivityType(null);
      },
    });
  }, [updateActivityType, closeDeleteModal, selectedActivityType]);

  const activityTypeStatusClickHandler = useCallback(
    (activityType: TActivityTypeItem) => {
      setSelectedActivityType(activityType);
      openDeleteModal();
    },
    [openDeleteModal],
  );

  const columns = useActivityTypeColumns({
    onStatusChange: activityTypeStatusClickHandler,
  });

  const { table } = useDataTable({
    columns,
    data: activities,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['name'],
        right: ['actions'],
      },
      columnVisibility: {
        is_recurrence: false,
        created_by__last_name: false,
        created_at: false,
        district: false,
      },
    },
    getRowId: (originalRow) => originalRow.id,
  });

  const districtChangeHandler = useCallback(
    (districtId: string | null) => {
      if (districtId === null) {
        onClearQuery();
      } else {
        onQueryChange(districtId);
      }
    },
    [onQueryChange, onClearQuery],
  );

  const closeModalHandler = useCallback(() => {
    setSelectedActivityType(null);
  }, []);

  return (
    <>
      <div className="flex flex-col gap-4 px-4 pb-8 pt-6">
        <div className="flex w-full flex-wrap items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <DistrictFilterComboBox
              value={selectedDistrict}
              status={DISTRICT_STATUS.PUBLISHED}
              align="start"
              placeholder="Select District"
              className="w-fit min-w-44 max-w-72 gap-8"
              contentClassName="min-w-80"
              onChange={districtChangeHandler}
              onClear={onClearQuery}
            />
            <RadixSelect onValueChange={onStatusChange} value={statusQuery ?? ''}>
              <RadixSelectTrigger className="min-w-44 gap-6" hasClearButton={!!statusQuery} onClearCallback={onStatusClear}>
                <RadixSelectValue placeholder="Filter by Status" />
              </RadixSelectTrigger>
              <RadixSelectContent align="start">
                {ACTIVITY_TYPE_STATUS_OPTIONS.map(({ value, label }) => (
                  <RadixSelectItem key={value} value={value}>
                    {label}
                  </RadixSelectItem>
                ))}
              </RadixSelectContent>
            </RadixSelect>
            <SearchInput
              value={search}
              placeholder="Search"
              className="max-w-[300px]"
              onChange={onSearchChange}
              onClear={onClearSearch}
            />
          </div>
          <div className="flex items-center gap-4">
            <Button type="button" disabled>Create New</Button>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </div>
      {selectedDistrict
        ? (
            <DataTable table={table} loading={isFetching} />
          )
        : (
            <NoDataAvailable
              iconName="folder-open"
              title="No District Selected"
              description="Please select a district from the dropdown menu above to view and manage activity types for that specific district."
              className="min-h-96"
            />
          )}
      <CallToActionModal
        modalName={ModalType.ACTIVITY_TYPE_STATUS_CHANGE}
        modalTitle={selectedActivityType?.is_active ? `Deactivate ${selectedActivityType?.name}` : `Activate ${selectedActivityType?.name}`}
        modalDescription={`By clicking the button below, you will ${selectedActivityType?.is_active ? 'deactivate' : 'activate'} the activity type ${selectedActivityType?.name}.`}
        modalTextContent={`Are you sure you want to ${selectedActivityType?.is_active ? 'deactivate' : 'activate'} selected activity type?`}
        primaryButtonText={selectedActivityType?.is_active ? 'Deactivate' : 'Activate'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={activityTypeStatusChangeHandler}
        primaryButtonVariant={selectedActivityType?.is_active ? 'destructive_primary' : 'primary'}
        isLoading={isUpdatePending}
        onClose={closeModalHandler}
      />
    </>
  );
};
