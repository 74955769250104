import { useCallback, useId, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PurpleIcon } from '@purple/icons';
import { Button, Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, Form, Separator } from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { type TBulkSafRoutingRulesErrorResponse, useBulkUploadRoutingRules } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { bulkUploadSafRoutingRules } from './schema';
import { UploadFileStatistic } from './UploadFileStatistic';
import { UploadFormContent } from './UploadFormContent';
import type { FC } from 'react';
import type { TBulkUploadResponse } from '@purple/shared-types';
import type { TBulkUploadSafRoutingRulesSchema } from './schema';

export const BulkImportRuleModal: FC = () => {
  const formId = useId();
  const { isOpen, closeModal } = useModal(ModalType.BULK_IMPORT_ROUTING_RULES);

  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const [isReuploading, setIsReuploading] = useState(false);

  const [uploadedFileStatisticData, setUploadedFileStatisticData] = useState<TBulkUploadResponse<TBulkSafRoutingRulesErrorResponse> | null>(null);

  const { mutate: bulkUpload, isPending: isUploading } = useBulkUploadRoutingRules();

  const form = useForm<TBulkUploadSafRoutingRulesSchema>({
    mode: 'onChange',
    resolver: zodResolver(bulkUploadSafRoutingRules),
    defaultValues: {
      district: '',
      schools: [],
      file: [],
    },
  });

  const uploadRulesHandler = useCallback((formData: TBulkUploadSafRoutingRulesSchema) => {
    const [uploadedFile] = formData.file;
    if (!uploadedFile) {
      showErrorToast('System message', 'We could not find the file you uploaded. Please try again.');
      return;
    }
    bulkUpload({
      district: formData.district,
      schools: formData.schools.map(({ value }) => value),
      uploaded_file: uploadedFile,
    }, {
      onSuccess: (data) => {
        setUploadedFileStatisticData(data);
        setHasFileUploaded(true);
      },
    });
  }, [bulkUpload]);

  const reuploadFileHandler = useCallback(() => {
    form.setValue('file', []);
    setIsReuploading(true);
    setHasFileUploaded(false);
  }, [form]);

  const closeModalHandler = useCallback(() => {
    closeModal();
    form.reset();
    setUploadedFileStatisticData(null);
    setHasFileUploaded(false);
    setIsReuploading(false);
  }, [form, closeModal]);

  return (
    <Dialog open={isOpen} onOpenChange={closeModalHandler}>
      <DialogContent className="flex max-h-[calc(100vh-32px)] w-full max-w-[calc(100vw-120px)] flex-col xl:max-w-[1200px]">
        <DialogHeader className="flex flex-row items-center justify-between">
          <div className="flex flex-col gap-1">
            <DialogTitle className="leading-5">SAF Routing Rules Import</DialogTitle>
            <DialogDescription className="sr-only">Modal window to bulk import saf routing rules</DialogDescription>
          </div>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        {hasFileUploaded
          ? (
              <UploadFileStatistic fileStatisticData={uploadedFileStatisticData} />
            )
          : (
              <Form providerProps={form} id={formId} className="p-6" onSubmit={form.handleSubmit(uploadRulesHandler)}>
                <UploadFormContent isReuploading={isReuploading} />
              </Form>
            )}
        <Separator />
        <DialogFooter>
          {!hasFileUploaded && (
            <Button variant="tertiary" onClick={closeModalHandler}>
              Cancel
            </Button>
          )}
          {hasFileUploaded && (
            <Button variant="primary" onClick={reuploadFileHandler}>
              Reupload File
            </Button>
          )}
          {!hasFileUploaded && (
            <Button variant="primary" type="submit" form={formId} isLoading={isUploading}>
              {false ? 'Uploading...' : 'Upload File'}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
