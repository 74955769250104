import { Link } from 'react-router-dom';
import { formatDateShortMonth } from '@purple/shared-utils';
import { Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTableColumnHeader, SelectableCell } from '~/components';
import { AdminRoutes } from '~/constants';
import { ListItemOptions, PriorityColumn, StatusColumn } from './components';
import type { ColumnDef } from '@tanstack/react-table';
import type { TAdminSafItem } from '~/services/saf';

type TSafsColumns = ColumnDef<TAdminSafItem>[];

type TUseAllSafsColumnsProperties = {
  onDelete: (saf: TAdminSafItem) => void;
  onTransfer: (saf: TAdminSafItem) => void;
};

export const useAllSafsColumns = ({ onDelete, onTransfer }: TUseAllSafsColumnsProperties): TSafsColumns => {
  return [
    SelectableCell(),
    {
      accessorKey: 'saf_number',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Saf Number" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <Link to={AdminRoutes.App.Safs.SafDetail.makePath({
              dynamicParameters: { id: row.original.id },
              queries: {
                original_district: row.original.district.id.toString(),
              },
            })}
            >
              <span className="text-brand-blue-700 line-clamp-1 cursor-pointer break-all">{row.getValue('saf_number')}</span>
            </Link>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.getValue('saf_number')}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 100,
      meta: { label: 'Saf Number' },
    },
    {
      accessorKey: 'subject',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Subject" />,
      cell: ({ row }) => (
        <Tooltip>
          <TooltipTrigger asChild>
            <span className="text-grey-950 line-clamp-1 break-all">{row.getValue('subject')}</span>
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent align="start" withArrow={false}>
              {row.getValue('subject')}
            </TooltipContent>
          </TooltipPortal>
        </Tooltip>
      ),
      size: 180,
      meta: { label: 'Subject' },
      enableSorting: false,
    },
    {
      accessorKey: 'priority',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Priority" />,
      cell: ({ row }) => (
        row.original.priority ? (<PriorityColumn priority={row.original.priority} />) : <span className="text-grey-950 line-clamp-1 break-all">-</span>
      ),
      size: 120,
      meta: { label: 'Priority' },
    },
    {
      accessorKey: 'submitter__last_name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Submitted By" />,
      cell: ({ row }) => {
        const submittedBy = row.original.created_by;

        if (!submittedBy) {
          return <span className="text-grey-950 line-clamp-1 break-all">-</span>;
        }

        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({
                dynamicParameters: { id: submittedBy.id },
              })}
              >
                <span className="text-brand-blue-700 line-clamp-1 cursor-pointer break-all">{submittedBy?.full_name}</span>
              </Link>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                {submittedBy?.full_name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 120,
      meta: { label: 'Submitted By' },
    },
    {
      accessorKey: 'current_owner__last_name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Owner" />,
      cell: ({ row }) => {
        const owner = row.original.current_owner;

        if (!owner) {
          return <span className="text-grey-950 line-clamp-1 break-all">-</span>;
        }

        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <Link to={AdminRoutes.App.Users.UserDetail.Root.makePath({
                dynamicParameters: { id: owner.id },
              })}
              >
                <span className="text-brand-blue-700 line-clamp-1 cursor-pointer break-all">{owner?.full_name}</span>
              </Link>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                {owner?.full_name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 120,
      meta: { label: 'Owner' },
    },
    {
      accessorKey: 'school',
      header: ({ column }) => <DataTableColumnHeader column={column} title="School" />,
      cell: ({ row }) => {
        const school = row.original.school;

        if (!school) {
          return <span className="text-grey-950 line-clamp-1 break-all">-</span>;
        }

        return (
          <Tooltip>
            <TooltipTrigger asChild>
              <Link to={AdminRoutes.App.Schools.Details.makePath({
                dynamicParameters: { schoolId: school.id },
              })}
              >
                <span className="text-brand-blue-700 line-clamp-1 cursor-pointer break-all">{school?.name}</span>
              </Link>
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                {school?.name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 120,
      meta: { label: 'School' },
    },
    {
      accessorKey: 'status',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Status" />,
      cell: ({ row }) => (
        row.original.status ? (<StatusColumn status={row.original.status} />) : <span className="text-grey-950 line-clamp-1 break-all">-</span>
      ),
      size: 120,
      meta: { label: 'Status' },
    },
    {
      accessorKey: 'student__last_name',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Student" />,
      cell: ({ row }) => {
        const student = row.original.student;
        return (
          <Tooltip>
            <TooltipTrigger asChild>
              {student ? <span className="text-brand-blue-700 line-clamp-1 cursor-pointer break-all">{student?.full_name}</span> : <span className="text-grey-950 line-clamp-1 break-all">-</span>}
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent align="start" withArrow={false}>
                {student?.full_name}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        );
      },
      size: 120,
      meta: { label: 'Student' },
    },
    {
      accessorKey: 'created_at',
      header: ({ column }) => <DataTableColumnHeader column={column} title="Created On" />,
      cell: ({ row }) => {
        const created_at = row.original.created_at;
        const dateString = created_at ? formatDateShortMonth(created_at) : '-';
        return (
          <span className="text-grey-950 line-clamp-1 break-all">{dateString}</span>
        );
      },
      size: 120,
      meta: { label: 'Created On' },
    },
    {
      id: 'options',
      cell: ({ row }) => (
        <div>
          <ListItemOptions onDelete={() => onDelete(row.original)} onTransfer={() => onTransfer(row.original)} />
        </div>
      ),
      size: 10,
      meta: { className: 'text-right' },
    },
  ];
};
