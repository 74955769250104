import { useMemo } from 'react';
import { useTabs } from '@purple/hooks';
import { APP_PERMISSIONS } from '@purple/permissions';
import { useContactsStatistic } from '~/services';
import { CONTACTS_PAGE_TABS_VALUES } from './constants';
import { ContactsTab } from './Contacts';
import { PriorityListQueryName, PriorityListsTab } from './PriorityLists';
import type { TAppTab } from '@purple/ui';

export const useContactsTabs = () => {
  const { data, isLoading } = useContactsStatistic();

  const contactsTabs: TAppTab[] = useMemo(() =>
    [
      {
        label: `Contacts (${isLoading ? '...' : (data?.contacts ?? 0)})`,
        value: CONTACTS_PAGE_TABS_VALUES.CONTACTS,
        content: <ContactsTab />,
        permissions: [APP_PERMISSIONS.CAN_ACCESS_ADMIN_CONTACTS],
      },
      {
        label: `My Priority Lists (${isLoading ? '...' : (data?.priority_lists ?? 0)})`,
        value: CONTACTS_PAGE_TABS_VALUES.PRIORITY_LISTS,
        content: <PriorityListsTab />,
        permissions: [],
      },
    ], [data?.contacts, data?.priority_lists, isLoading]);

  const { activeTab, tabChangeHandler, isReady } = useTabs({
    tabs: contactsTabs,
    defaultTab: CONTACTS_PAGE_TABS_VALUES.CONTACTS,
  });

  const onTabChange = (value: string) => {
    tabChangeHandler(value, {
      onSuccess: (params) => {
        params.delete(PriorityListQueryName.ID);
        params.delete(PriorityListQueryName.VIEW);
      },
    });
  };

  return {
    isTabsReady: isReady,
    activeTab,
    contactsTabs,
    tabChangeHandler: onTabChange,
  };
};
