import { useCallback, useMemo, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { ALLOWED_ACTIVITY_FILE_EXTENSIONS, MAX_ACTIVITY_FILE_SIZE } from '@purple/shared-utils';
import { Button, ComboBox, ComboBoxContent, ComboBoxItem, ComboBoxTrigger, SearchInput } from '@purple/ui';
import { CallToActionModal, DataTable, DataTableViewOptions, EditFileDialog, UploadFilePreviewDialog } from '~/components';
import { ModalType } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useActivityFilterChoicesById, useDeleteFile, useFiles } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import { ActivityTabContainer } from '../../components';
import { useActivityFileColumns } from './useActivityFileColumns';

export const ActivityFilesTab: React.FC = () => {
  const { id: activityId } = useParams();
  const [searchParameters] = useSearchParams();

  const { query: createdByQuery, onClearQuery, onQueryChange } = useQueryParameter({
    queryName: 'created_by',
  });

  const inputReference = useRef<HTMLInputElement>(null);

  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const { openModal } = useModal(ModalType.UPLOAD_FILE_PREVIEW);
  const { closeModal: closeDeleteModal } = useModal(ModalType.DELETE_FILE);

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = useFiles({
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    community_activity: activityId as string,
    created_by: createdByQuery,
  });
  const { data: choices, isFetching: isFetchingChoices } = useActivityFilterChoicesById(activityId as string);
  const { mutate: deleteFile, isPending: isDeletePending } = useDeleteFile();

  const files = useMemo(() => data?.results ?? [], [data?.results]);
  const owners = useMemo(() => choices?.file_owners ?? [], [choices?.file_owners]);

  const columns = useActivityFileColumns(
    { onSelectFileId: setSelectedFileId, onSelectFile: setUploadedFile },
  );

  const { table } = useDataTable({
    columns,
    data: files,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        right: ['actions'],
      },
    },
    getRowId: (originalRow) => originalRow.id,
  });

  const fileUploadHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    inputReference.current?.click();
  };

  const fileChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const [file] = files ?? [];
    if (!file) return;

    if (file.size > MAX_ACTIVITY_FILE_SIZE) {
      showErrorToast('System message', `File size exceeds the limit of ${MAX_ACTIVITY_FILE_SIZE / 1024 / 1024}MB`);
      inputReference.current!.value = '';
      return;
    }
    if (file.name.length >= 100) {
      showErrorToast('System message', 'File name has to be less than 100 characters');
      inputReference.current!.value = '';
      return;
    }

    setUploadedFile(file);
    openModal();
    inputReference.current!.value = '';
  };

  const deleteFileConfirmHandler = useCallback(() => {
    if (!selectedFileId) {
      return showErrorToast('Banner not found', 'Please select a banner to delete');
    }
    deleteFile(selectedFileId, {
      onSuccess: () => {
        closeDeleteModal();
        setSelectedFileId(null);
      },
    });
  }, [deleteFile, selectedFileId, closeDeleteModal]);

  const closeModalHandler = useCallback(() => {
    setSelectedFileId(null);
  }, []);

  return (
    <ActivityTabContainer title="Files" className="p-0">
      <DataTable table={table} loading={isFetching}>
        <div className="flex w-full items-center justify-between gap-4 p-4 pt-6">
          <div className="flex items-center gap-4">
            <ComboBox modal>
              <ComboBoxTrigger
                className="w-fit gap-7"
                disabled={isFetchingChoices || owners.length === 0}
                placeholder="All Owners"
                hasClearButton={!!createdByQuery}
                selectedLabel={owners.find(({ id }) => id === createdByQuery)?.full_name}
                onClear={onClearQuery}
              />
              <ComboBoxContent
                searchPlaceholder="Search owner..."
                emptyContent="User not found."
                align="start"
                className="min-w-56"
              >
                {owners.map(({ id, full_name }) => (
                  <ComboBoxItem
                    key={id}
                    keywords={[full_name ?? 'Unknown']}
                    value={id}
                    selected={id === createdByQuery}
                    onSelect={onQueryChange}
                  >
                    {full_name ?? 'Unknown'}
                  </ComboBoxItem>
                ))}
              </ComboBoxContent>
            </ComboBox>
            <SearchInput
              value={search}
              placeholder="Search"
              className="max-w-[300px]"
              onChange={onSearchChange}
              onClear={onClearSearch}
            />
          </div>
          <div className="flex items-center gap-4">
            <Button type="button" onClick={fileUploadHandler} iconLeft={<PurpleIcon name="upload" />}>
              Upload File
            </Button>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </DataTable>
      <input
        type="file"
        className="hidden"
        accept={ALLOWED_ACTIVITY_FILE_EXTENSIONS}
        ref={inputReference}
        onChange={fileChangeHandler}
      />
      <UploadFilePreviewDialog
        contentType="communityactivity"
        objectId={activityId}
        uploadedFile={uploadedFile}
        selectedFileId={selectedFileId}
        setUploadedFile={setUploadedFile}
        setSelectedFileId={setSelectedFileId}
      />
      <EditFileDialog
        selectedFileId={selectedFileId}
        setSelectedFileId={setSelectedFileId}
      />
      <CallToActionModal
        modalName={ModalType.DELETE_FILE}
        modalTitle="Delete File"
        modalDescription="By deleting this file, it will be removed from the system and can't be recovered."
        modalTextContent="Are you sure you want to delete this file?"
        primaryButtonText={isDeletePending ? 'Deleting' : 'Delete'}
        secondaryButtonText="Cancel"
        onPrimaryButtonClick={deleteFileConfirmHandler}
        primaryButtonVariant="destructive_primary"
        isLoading={isDeletePending}
        onClose={closeModalHandler}
      />
    </ActivityTabContainer>
  );
};
