import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Button, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { PRINT_OPTIONS } from '~/pages/System';
import { PRINT_QUERY_KEYS, usePrint, useSafListExportMutation } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { DISTRICT_QUERY_NAME, PRIORITY_QUERY_NAME, STATUS_QUERY_NAME } from '../../constants';
import type { FC } from 'react';
import type { TAdminSafsListRequestParameters } from '~/services';

type TPrintDropdownProps = {
  safRequestParameters: TAdminSafsListRequestParameters;
};

const PrintDropdown: FC<TPrintDropdownProps> = ({ safRequestParameters }) => {
  const [searchParameters] = useSearchParams();

  const { mutate: exportSafs, isPending: isExportingSafs } = useSafListExportMutation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { mutate: printSafList, isPending } = usePrint(PRINT_QUERY_KEYS.ADMIN_SAF_LIST);

  const exportClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    exportSafs({
      priority: searchParameters.get(PRIORITY_QUERY_NAME),
      status: searchParameters.get(STATUS_QUERY_NAME),
      district: searchParameters.get(DISTRICT_QUERY_NAME),
      search: searchParameters.get(SEARCH_QUERY_NAME),
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
    }, {
      onSuccess: () => {
        showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
        setIsDropdownOpen(false);
      },
      onError: () => {
        setIsDropdownOpen(false);
      },
    });
  };

  const printClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    printSafList({
      variant: PRINT_OPTIONS.SAF_LIST,
      scale: 1,
      landscape: 'true',
      ...safRequestParameters,
    }, {
      onSuccess: () => {
        setIsDropdownOpen(false);
      },
    });
  };

  return (
    <DropdownRoot open={isDropdownOpen} onOpenChange={setIsDropdownOpen}>
      <DropdownTrigger>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button type="button" variant="secondary" size="icon_40" iconLeft={<PurpleIcon name="printer" />} />
          </TooltipTrigger>
          <TooltipPortal>
            <TooltipContent>Print/Export</TooltipContent>
          </TooltipPortal>
        </Tooltip>
      </DropdownTrigger>
      <DropdownContent align="end" sideOffset={4} className="w-[240px] border-grey-200">
        <DropdownItem isLoading={isPending} iconName="printer" onClick={printClickHandler}>
          Print Preview
        </DropdownItem>
        <DropdownItem isLoading={isExportingSafs} onClick={exportClickHandler} iconName="document-text">
          Export to xlsx
        </DropdownItem>
      </DropdownContent>
    </DropdownRoot>
  );
};

export { PrintDropdown };
