import { useCallback, useMemo, useState } from 'react';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
  ScrollArea,
  SearchInput,
  Separator,
  Switch,
  Text,
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from '@purple/ui';
import type { RowData, Table, VisibilityState } from '@tanstack/react-table';

type TDataTableViewOptionsProperties<TData> = {
  table: Table<TData>;
};

export const DataTableViewOptions = <TData extends RowData = RowData>({
  table,
}: TDataTableViewOptionsProperties<TData>): JSX.Element => {
  const allColumns = table
    .getAllColumns()
    .filter((column) => column.accessorFn !== undefined && column.getCanHide());

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [visibilityState, setVisibilityState] = useState<VisibilityState>(
    allColumns.reduce<VisibilityState>((acc, column) => ({
      ...acc,
      [column.id]: table.initialState.columnVisibility[column.id] ?? true,
    }), {}),
  );
  const isSameAsInitialState = useMemo(() =>
    allColumns.every((column) => visibilityState[column.id] === (table.initialState.columnVisibility[column.id] ?? true)), [visibilityState, table, allColumns]);
  const columns = useMemo(
    () =>
      allColumns.filter((column) => (column.columnDef.meta?.label ?? column.id).trim().toLowerCase().includes(search.trim().toLowerCase())),
    [allColumns, search],
  );
  const isEmpty = columns.length === 0;

  const resetToDefaultClickHandler = useCallback(() => {
    const initialVisibleColumns = table.initialState.columnVisibility;
    const allColumnIds = allColumns.map((column) => column.id);
    const newColumnVisibility = allColumnIds.reduce<VisibilityState>((acc, column) => ({
      ...acc,
      [column]: initialVisibleColumns[column] ?? true,
    }), {});
    setVisibilityState(newColumnVisibility);
  }, [table, allColumns]);

  const applyClickHandler = useCallback(() => {
    table.setColumnVisibility(visibilityState);
    setIsOpen(false);
    setSearch('');
  }, [visibilityState, table]);

  const searchChangeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  const openChangeHandler = useCallback((open: boolean) => {
    if (!open) {
      setVisibilityState(
        allColumns.reduce<VisibilityState>((acc, column) => ({
          ...acc,
          [column.id]: table.getState().columnVisibility[column.id] ?? true,
        }), {}),
      );
      setSearch('');
    }
    setIsOpen(open);
  }, [table, allColumns]);

  return (
    <Popover modal open={isOpen} onOpenChange={openChangeHandler}>
      <Tooltip>
        <TooltipTrigger asChild>
          <PopoverTrigger asChild>
            <Button
              aria-label="Toggle columns"
              variant="secondary"
              size="icon_40"
              iconLeft={<PurpleIcon name="adjustments" className="size-5 rotate-90" />}
              className="ml-auto hidden size-10 md:flex"
              onFocusCapture={(event) => event.stopPropagation()}
            />
          </PopoverTrigger>
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent>Show or hide columns in the table</TooltipContent>
        </TooltipPortal>
      </Tooltip>
      <PopoverContent align="end" className="w-[360px] rounded-lg border-0 p-0 shadow-custom-medium">
        <div className="flex w-full flex-col gap-3 p-4">
          <Text tag="strong" className="font-primary text-lg font-semibold text-grey-title">
            Configure Columns
          </Text>
          <SearchInput
            name="column-search"
            placeholder="Search column"
            value={search}
            onChange={searchChangeHandler}
            onClear={() => setSearch('')}
          />
        </div>
        <div className="flex w-full flex-col">
          <Text tag="small" className="px-4 font-primary text-sm font-medium text-grey-700">
            Available Columns
          </Text>
          <div className="mt-1.5 px-4">
            <Separator orientation="horizontal" className="bg-grey-200" />
          </div>
          <ScrollArea type="auto" className="flex max-h-[224px] w-full flex-col p-0" scrollBarClassName="p-2 w-[22px]">
            {isEmpty
              ? (
                  <Text className="px-4 py-5 text-center text-base font-medium text-grey-600">
                    {search ? 'No matching columns found' : 'No columns to display'}
                  </Text>
                )
              : (
                  <ul className="flex w-full flex-col gap-3 px-4 py-3 pr-[26px]">
                    {columns.map((column) => (
                      <li key={column.id} className="flex w-full items-center justify-between gap-2">
                        <Label htmlFor={column.id} className="line-clamp-1 break-all text-base font-normal capitalize text-grey-950">
                          {column.columnDef.meta?.label ?? column.id}
                        </Label>
                        <Switch
                          id={column.id}
                          name={column.id}
                          className="cursor-pointer"
                          checked={visibilityState[column.id] ?? true}
                          onCheckedChange={(value) => setVisibilityState((prevState) => ({
                            ...prevState,
                            [column.id]: value,
                          }))}
                        />
                      </li>
                    ))}
                  </ul>
                )}
          </ScrollArea>
        </div>
        <div className="flex w-full flex-col gap-4 px-4 pb-4">
          <Separator orientation="horizontal" className="bg-grey-200" />
          <div className="flex w-full items-center justify-between gap-4">
            <Button
              type="button"
              variant="secondary"
              disabled={isSameAsInitialState}
              className="w-full"
              onClick={resetToDefaultClickHandler}
            >
              Reset to Default
            </Button>
            <Button
              type="button"
              variant="primary"
              disabled={Object.values(visibilityState).every((value) => !value)}
              className="w-full"
              onClick={applyClickHandler}
            >
              Apply
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
