import { useSearchParams } from 'react-router-dom';
import { OFFSET_QUERY_NAME, SEARCH_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AccessDenied } from '@purple/ui';
import { DataTable } from '~/components';
import { useDataTable } from '~/hooks';
import { useUsersListManagement } from '~/services';
import { userPrintColumns } from './usersPrintColumns';
import type { FC } from 'react';

export const UsersPrintView: FC = () => {
  const [searchParameters] = useSearchParams();

  const authToken = searchParameters.get('token');

  const { data, isLoading, isSuccess, isError } = useUsersListManagement({
    district: searchParameters.get('district'),
    search: searchParameters.get(SEARCH_QUERY_NAME),
    limit: 999999999,
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    school: searchParameters.get('school'),
    status: searchParameters.get('status'),
    role: searchParameters.get('role'),
    token: authToken,
    enabled: !!authToken,
  });

  const { table } = useDataTable({
    columns: userPrintColumns,
    data: data?.results || [],
    pageCount: 1,
  });

  if (isError || !authToken) {
    return (
      <div className="flex h-screen items-center justify-center">
        <AccessDenied />
      </div>
    );
  }

  return (
    <DataTable table={table} hidePagination loading={isLoading} id={isSuccess ? 'download-ready' : ''} />
  );
};
