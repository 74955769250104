import axiosInstance from '../axios-config';
import { ADMIN_USER_MANAGEMENT } from '../endpoints';
import type { TAdminLoggedInByRoleReportParameters } from './user.types';

export const getLoggedInByRoleReportExport = async (parameters: TAdminLoggedInByRoleReportParameters) => {
  const response = await axiosInstance.get<any>(ADMIN_USER_MANAGEMENT.LOGGED_IN_BY_ROLE_REPORT_EXPORT, {
    params: parameters,
  });
  return response.data;
};
