import { useMemo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { format, subWeeks } from 'date-fns';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { Button, SearchInput, Switch, Text, Tooltip, TooltipContent, TooltipPortal, TooltipTrigger } from '@purple/ui';
import { DataTable, DataTableViewOptions, DistrictMultiSelect } from '~/components';
import { AdminRoutes } from '~/constants';
import { useDataTable } from '~/hooks';
import { DISTRICT_STATUSES, useNotLoggedInUsersReport, useNotLoggedInUsersReportExportMutation } from '~/services';
import { showSuccessToast } from '~/utils/toasts';
import { reportColumns } from './reportColumns';

const OFFSET_WEEKS_BEFORE = 2;

const DISTRICT_QUERY_NAME = 'district';
const NEWER_LOGIN_QUERY_NAME = 'is_never';

const NotLoggedInReport = () => {
  const [searchParameters] = useSearchParams();

  const { query: districtQuery, onQueryChange: onDistrictQueryChange } = useQueryParameter({ queryName: DISTRICT_QUERY_NAME, resetOffset: true });

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();

  const { query: newerLoginQuery, onQueryChange: onNewerLoginQueryChange } = useQueryParameter({ queryName: NEWER_LOGIN_QUERY_NAME, resetOffset: true });

  const isNewerLogin = useMemo(() => newerLoginQuery === 'true', [newerLoginQuery]);

  const { data: reportData, isLoading: isReportLoading } = useNotLoggedInUsersReport({
    requestParameters: {
      ...(districtQuery && { district: districtQuery }),
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      is_never: isNewerLogin,
    },
  });

  const { mutate: exportReport, isPending: isExportingReport } = useNotLoggedInUsersReportExportMutation();

  const users = useMemo(() => reportData?.results ?? [], [reportData?.results]);

  const { table } = useDataTable({
    columns: reportColumns,
    data: users,
    rowCount: reportData?.count,
  });

  const selectedDistricts = useMemo(() => {
    const array = districtQuery?.split(',') || [];
    return array;
  }, [districtQuery]);

  const twoWeeksBeforeDate = useMemo(() => {
    const currentDate = new Date();
    const dateMinusTwoWeeks = subWeeks(currentDate, OFFSET_WEEKS_BEFORE);
    const formattedDate = format(dateMinusTwoWeeks, 'MMM dd, yyyy');
    return formattedDate;
  }, []);

  const exportClickHandler = () => {
    if (reportData) {
      exportReport({
        ...(districtQuery && { district: districtQuery }),
        search: debounceSearch,
        limit: reportData.count,
        offset: searchParameters.get(OFFSET_QUERY_NAME),
        ordering: searchParameters.get(SORT_QUERY_NAME),
        is_never: newerLoginQuery === 'true',
      }, {
        onSuccess: () => {
          showSuccessToast('System message', 'The file has been successfully exported and downloaded to the device');
        },
      });
    }
  };

  const districtChangeHandler = (newValue: string[]) => {
    onDistrictQueryChange(newValue.join(','));
  };

  return (
    <div className="flex w-full flex-1 flex-col items-start gap-4">
      <Button
        variant="link"
        size="small"
        iconLeft={<PurpleIcon name="chevron-left" className="size-4 shrink-0 text-brand-blue-700" />}
        className="h-auto p-1 font-semibold"
        asChild
      >
        <Link to={AdminRoutes.App.Home.Root.path}>
          Back to Home Page
        </Link>
      </Button>
      <DataTable table={table} loading={isReportLoading} className="rounded-lg border border-grey-200 bg-white shadow-custom-heavy">
        <div className="flex w-full flex-col gap-4 p-4 pt-6">
          <Text variant="size-16" type="body-600" className="text-grey-title">
            Not Logged In Users (from now to
            {' '}
            {twoWeeksBeforeDate}
            )
          </Text>
          <div className="flex w-full items-center justify-between gap-4">
            <div className="flex w-full items-center gap-4">
              <div className="min-w-[200px] max-w-[200px]">
                <SearchInput
                  value={search}
                  placeholder="Search"
                  className="max-w-[300px]"
                  onChange={onSearchChange}
                  onClear={onClearSearch}
                />
              </div>
              <div className="min-w-[200px]">
                <DistrictMultiSelect values={selectedDistricts || ''} onChange={districtChangeHandler} placeholder="Select district" status={DISTRICT_STATUSES.PUBLISHED} />
              </div>
              <div className="flex items-center gap-4">
                <Switch
                  checked={isNewerLogin}
                  onCheckedChange={() => {
                    onNewerLoginQueryChange(!isNewerLogin ? 'true' : 'false');
                  }}
                  className="cursor-pointer"
                />
                <Text variant="size-16" type="body-500" className="text-grey-950">Never Logged In Users</Text>
              </div>
            </div>

            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Button type="button" variant="primary" size="icon_40" iconLeft={<PurpleIcon name="download" />} onClick={exportClickHandler} isLoading={isExportingReport}>Export</Button>
                </div>
              </TooltipTrigger>
              <TooltipPortal>
                <TooltipContent>Export</TooltipContent>
              </TooltipPortal>
            </Tooltip>
            <DataTableViewOptions table={table} />
          </div>
        </div>
      </DataTable>
    </div>
  );
};

export { NotLoggedInReport };
