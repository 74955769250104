import { useEffect, useId, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDebounceValue } from 'usehooks-ts';
import { z } from 'zod';
import { PurpleIcon } from '@purple/icons';
import {
  Button,
  ComboBox,
  ComboBoxContent,
  ComboBoxItem,
  ComboBoxTrigger,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Separator,
} from '@purple/ui';
import { ModalType } from '~/constants';
import { useModal } from '~/hooks';
import { useContactsPriorityLists, usePriorityListBatchAddContacts } from '~/services';
import { showErrorToast } from '~/utils/toasts';
import type React from 'react';

const addToListSchema = z
  .object({
    priority_list: z.string().trim().min(1, 'Priority list is required.'),
  });

const DEFAULT_CONTACTS: string[] = [];

type TAddToExistingListDialogProperties = {
  contacts?: string[];
  districtId?: number | null;
  onSuccess?: () => void;
};

export const AddToExistingListDialog: React.FC<TAddToExistingListDialogProperties> = (props) => {
  const { contacts = DEFAULT_CONTACTS, districtId = null, onSuccess } = props;

  const formId = useId();

  const [listSearch, setListSearch] = useState<string>('');
  const [debouncedSearchList] = useDebounceValue(listSearch, 500);

  const { isOpen, toggleModal, closeModal } = useModal(ModalType.ADD_TO_EXISTING_CONTACTS_PRIORITY_LIST);

  const { data, isFetching } = useContactsPriorityLists({
    search: debouncedSearchList,
    limit: 30,
  });
  const { mutate: addContactsToPriorityList, isPending: isAddPending } = usePriorityListBatchAddContacts();

  const listOptions = useMemo(() => (data?.pages || [])
    .map((page) => page.results)
    .flat()
    .filter((list) => list.district.id === districtId)
    .map(({ id, name }) => ({
      label: name,
      value: id,
    })), [data, districtId]);

  const defaultValues = useMemo(
    () => ({
      priority_list: '',
    }),
    [],
  );

  const form = useForm<z.infer<typeof addToListSchema>>({
    resolver: zodResolver(addToListSchema),
    mode: 'onChange',
    defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  const successCallback = () => {
    closeModal();
    form.reset(defaultValues);
    onSuccess?.();
  };

  const addContactsPriorityListHandler = (data: z.infer<typeof addToListSchema>) => {
    if (contacts.length === 0) {
      return showErrorToast('System Error', 'No contacts to add to the priority list.');
    }

    addContactsToPriorityList({
      id: data.priority_list,
      contacts,
    }, {
      onSuccess: successCallback,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={toggleModal}>
      <DialogContent className="w-[564px]">
        <DialogHeader className="flex-row items-center justify-between">
          <DialogTitle>
            Add to Existing Priority List
          </DialogTitle>
          <DialogDescription className="sr-only">
            By adding contacts to the existing priority list, you can extend the list of users who have access to it.
          </DialogDescription>
          <DialogClose asChild>
            <Button variant="tertiary" size="icon_32" iconLeft={<PurpleIcon name="X" />} />
          </DialogClose>
        </DialogHeader>
        <Separator />
        <Form
          id={formId}
          providerProps={form}
          className="flex w-full flex-col gap-4 p-6"
          onSubmit={form.handleSubmit(addContactsPriorityListHandler)}
        >
          <FormField
            control={form.control}
            name="priority_list"
            render={({ field, fieldState }) => (
              <FormItem>
                <FormLabel required>Priority List</FormLabel>
                <FormControl>
                  <ComboBox modal>
                    <ComboBoxTrigger
                      isError={!!fieldState.error}
                      placeholder="Select list"
                      selectedLabel={listOptions.find(({ value }) => value === field.value)?.label}
                    />
                    <ComboBoxContent
                      loading={isFetching}
                      shouldFilter={false}
                      searchValue={listSearch}
                      onSearchChange={setListSearch}
                      searchPlaceholder="Search list..."
                      emptyContent="List not found."
                      align="start"
                    >
                      {listOptions.map(({ label, value }) => (
                        <ComboBoxItem key={value} value={value} selected={value === field.value} onSelect={field.onChange}>
                          {label}
                        </ComboBoxItem>
                      ))}
                    </ComboBoxContent>
                  </ComboBox>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
        <Separator />
        <DialogFooter>
          <Button variant="tertiary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" form={formId} isLoading={isAddPending}>
            {isAddPending ? 'Submitting...' : 'Submit'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
