import { type FC, useCallback, useState } from 'react';

import { PurpleIcon } from '@purple/icons';
import { Button, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger } from '@purple/ui';
import { PRINT_OPTIONS } from '~/pages/System';
import { PRINT_QUERY_KEYS, usePrint } from '~/services';
import type { TContactsListRequestParameters } from '~/services';

type TPrintDropDownProps = {
  contactsRequestParameters: TContactsListRequestParameters;
};

export const PrintDropDown: FC<TPrintDropDownProps> = ({ contactsRequestParameters }) => {
  const { mutate, isPending } = usePrint(PRINT_QUERY_KEYS.ADMIN_CONTACTS_LIST);

  const [isOpen, setIsOpen] = useState(false);

  const printContactsHandler = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    mutate({
      variant: PRINT_OPTIONS.CONTACTS_LIST,
      scale: 1,
      landscape: 'true',
      ...contactsRequestParameters,
    });
  }, [mutate, contactsRequestParameters]);

  return (
    <DropdownRoot open={isOpen} onOpenChange={setIsOpen}>
      <DropdownTrigger asChild>
        <Button variant="secondary" className="h-10 p-2.5">
          <PurpleIcon name="printer" className="size-5" />
        </Button>
      </DropdownTrigger>
      <DropdownContent align="end">
        <DropdownItem iconName="printer" isLoading={isPending} onClick={printContactsHandler}>
          Print Preview
        </DropdownItem>
        <DropdownItem iconName="table" onClick={() => {}}>
          Export to Excel
        </DropdownItem>
      </DropdownContent>
    </DropdownRoot>
  );
};
