import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryParameter, useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { SearchInput } from '@purple/ui';
import { DataTable, DistrictMultiSelect } from '~/components';
import { useDataTable } from '~/hooks';
import { DISTRICT_STATUSES, useActionTypes } from '~/services';
import { ActionsTabContainer } from '../../components';
import { actionTypesColumns } from './actionTypesColumns';
import type { FC } from 'react';

const DEFAULT_DISTRICTS_LIMIT = 50;

export const ActionTypes: FC = () => {
  const [searchParameters] = useSearchParams();

  const { query: districts, onQueryChange } = useQueryParameter({
    queryName: 'districts',
    resetOffset: true,
  });

  const [selectedDistricts, setSelectedDistricts] = useState<string[]>(districts ? districts.split(',') : []);

  const { debounceSearch, onClearSearch, onSearchChange, search } = useSearch();

  const { data: actionTypes, isLoading: isActionTypesLoading } = useActionTypes({
    parameters: {
      search: debounceSearch,
      limit: searchParameters.get(LIMIT_QUERY_NAME),
      offset: searchParameters.get(OFFSET_QUERY_NAME),
      ordering: searchParameters.get(SORT_QUERY_NAME),
      districts: selectedDistricts.join(','),
    },
  });

  const { table } = useDataTable({
    columns: actionTypesColumns,
    data: actionTypes?.results || [],
    rowCount: actionTypes?.count || 0,
  });

  const handleOptionChange = useCallback((value: string[]) => {
    onQueryChange(value.join(','), {
      onSuccess: () => {
        setSelectedDistricts(value);
      },
    });
  }, [onQueryChange]);

  return (
    <ActionsTabContainer title="Action Types" className="p-0">
      <DataTable table={table} loading={isActionTypesLoading}>
        <div className="flex items-center gap-4 p-4">
          <DistrictMultiSelect
            values={selectedDistricts}
            onChange={handleOptionChange}
            placeholder="All Districts"
            status={DISTRICT_STATUSES.PUBLISHED}
            limit={DEFAULT_DISTRICTS_LIMIT}
            className="w-max min-w-72"
            disabled={!selectedDistricts}
          />
          <SearchInput
            value={search}
            onChange={onSearchChange}
            onClear={onClearSearch}
            placeholder="Search"
            className="w-72"
          />
        </div>
      </DataTable>
    </ActionsTabContainer>
  );
};
