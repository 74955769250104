import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { SearchInput } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { useDataTable } from '~/hooks';
import { useActivityHistory } from '~/services';
import { ActivityTabContainer } from '../../components';
import { useActivityHistoryColumns } from './useActivityHistoryColumns';

export const ActivityHistoryTab: React.FC = () => {
  const { id: activityId } = useParams();
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = useActivityHistory({
    id: activityId as string,
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
  });

  const files = useMemo(() => data?.results ?? [], [data?.results]);

  const columns = useActivityHistoryColumns();

  const { table } = useDataTable({
    columns,
    data: files,
    rowCount: data?.count,
    getRowId: (originalRow) => originalRow.id.toString(),
  });

  return (
    <ActivityTabContainer title="History / Timeline" className="p-0">
      <DataTable table={table} loading={isFetching}>
        <div className="flex w-full items-center justify-between gap-4 p-4 pt-6">
          <SearchInput
            value={search}
            placeholder="Search"
            className="max-w-[300px]"
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
          <DataTableViewOptions table={table} />
        </div>
      </DataTable>
    </ActivityTabContainer>
  );
};
