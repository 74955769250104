import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSearch } from '@purple/hooks';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { convertToFilterConfig } from '@purple/shared-utils';
import { AppFilters, SearchInput } from '@purple/ui';
import { DataTable, DataTableViewOptions } from '~/components';
import { useDataTable } from '~/hooks';
import { useUserManagementFilterOptions, useUsersListManagement } from '~/services';
import { SchoolTabContainer } from '../../SchoolTabContainer';
import { useSchoolUsersColumns } from './useSchoolUsersColumns';

export const SchoolUsersTab: React.FC = () => {
  const { id: schoolId } = useParams();
  const [searchParameters] = useSearchParams();

  const { debounceSearch, search, onClearSearch, onSearchChange } = useSearch();
  const { data, isFetching } = useUsersListManagement({
    school: schoolId,
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    role: searchParameters.get('role'),
    status: searchParameters.get('status'),
  });
  const { data: options, isFetching: isOptionsLoading } = useUserManagementFilterOptions();

  const users = useMemo(() => data?.results ?? [], [data?.results]);
  const filters = useMemo(
    () =>
      options
        ? Object.entries(options).reduce(
            (accumulator, [key, value]) => (key !== 'school' ? { ...accumulator, [key]: value } : accumulator),
            {},
          )
        : null,
    [options],
  );
  const filterConfig = useMemo(
    () => (filters ? convertToFilterConfig(filters, { snakeCaseValue: false }) : { categories: [] }),
    [filters],
  );

  const columns = useSchoolUsersColumns();

  const { table } = useDataTable({
    columns,
    data: users,
    rowCount: data?.count,
    initialState: {
      columnPinning: {
        left: ['last_name'],
      },
      columnVisibility: {
        role: false,
      },
    },
    getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
  });

  return (
    <SchoolTabContainer title="Users" className="p-0">
      <DataTable table={table} loading={isFetching}>
        <div className="flex w-full items-center gap-4 p-4 pt-6">
          <AppFilters config={filterConfig} loading={isOptionsLoading} />
          <SearchInput
            value={search}
            placeholder="Search"
            className="max-w-[300px]"
            onChange={onSearchChange}
            onClear={onClearSearch}
          />
          <DataTableViewOptions table={table} />
        </div>
      </DataTable>
    </SchoolTabContainer>
  );
};
