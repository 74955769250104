import { type FC, useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useSearch } from '@purple/hooks';
import { PurpleIcon } from '@purple/icons';
import { LIMIT_QUERY_NAME, OFFSET_QUERY_NAME, SORT_QUERY_NAME } from '@purple/shared-types';
import { AppFilters, AppSelectedFiltersList, Button, DateRangePicker, DropdownContent, DropdownItem, DropdownRoot, DropdownTrigger, Heading, SearchInput, Text } from '@purple/ui';
import { AddContactModal, BulkImportContactsModal, DataTable, DataTableViewOptions } from '~/components';
import { ModalType } from '~/constants';
import { useDataTable, useModal } from '~/hooks';
import { useContacts, useContactsId } from '~/services';
import { isClientError } from '~/utils/api-requests';
import { showErrorToast } from '~/utils/toasts';
import { AddToExistingListDialog, ManagePriorityListDialog, SendEmailDialog } from '../../components';
import { PrintDropDown, PriorityListDropDown } from './components';
import { contactsColumns } from './useContactsColumns';
import { useContactsFilterOptions } from './useContactsFilterOptions';
import type { DateRange } from '@purple/ui';
import type { TContactItem } from '~/services';

export const ContactsTab: FC = () => {
  const [searchParameters, setSearchParameters] = useSearchParams();

  const [selectedContacts, setSelectedContacts] = useState<Pick<TContactItem, 'id' | 'district'>[]>([]);

  const isSameDistrict = useMemo(() => selectedContacts.length > 0 && selectedContacts.every((contact) => contact.district.id === selectedContacts[0]?.district.id), [selectedContacts]);
  const selectedDistrict = useMemo(() => isSameDistrict ? selectedContacts[0]?.district?.id ?? null : null, [isSameDistrict, selectedContacts]);

  const { openModal: openAddContactModal } = useModal(ModalType.ADD_CONTACT);
  const { openModal: openBulkContactsImportModal } = useModal(ModalType.BULK_IMPORT_CONTACTS);
  const { toggleModal: toggleEmailModal } = useModal(ModalType.SEND_EMAIL_TO_CONTACTS);
  const { toggleModal: toggleCreateListModal } = useModal(ModalType.MANAGE_CONTACTS_PRIORITY_LIST);
  const { toggleModal: toggleAddToListModal } = useModal(ModalType.ADD_TO_EXISTING_CONTACTS_PRIORITY_LIST);

  const { filterConfig, isFiltersLoading } = useContactsFilterOptions();

  const { onClearSearch, onSearchChange, search, debounceSearch } = useSearch();

  const initialDateFrom = searchParameters.get('created_at__gte') ?? undefined;
  const initialDateTo = searchParameters.get('created_at__lte') ?? undefined;

  const contactsRequestParams = useMemo(() => ({
    search: debounceSearch,
    limit: searchParameters.get(LIMIT_QUERY_NAME),
    offset: searchParameters.get(OFFSET_QUERY_NAME),
    ordering: searchParameters.get(SORT_QUERY_NAME),
    district: searchParameters.get('district'),
    type: searchParameters.get('type'),
    created_at__gte: initialDateFrom,
    created_at__lte: initialDateTo,
  }), [debounceSearch, searchParameters, initialDateFrom, initialDateTo]);

  const { data: contactsList, isLoading } = useContacts(contactsRequestParams);

  const { mutate: selectAllAvailable, isPending } = useContactsId();

  const contacts = useMemo(() => contactsList?.results || [], [contactsList]);

  const { table } = useDataTable({
    columns: contactsColumns,
    data: contacts,
    rowCount: contactsList?.count || 0,
    getRowId: (row) => row.id,
    onSelectionChange: (selectedRows) => {
      const selectedIds = Object.keys(selectedRows);
      const updatedSelectedContacts = [...selectedContacts, ...contacts]
        .filter((contact, index, self) => index === self.findIndex((c) => c.id === contact.id))
        .filter((contact) => selectedIds.includes(contact.id));
      setSelectedContacts(updatedSelectedContacts);
    },
  });

  const clearAllSelected = useCallback(() => {
    table.setRowSelection({});
    setSelectedContacts([]);
  }, [table]);

  const currentSelectedRowsAmount = Object.keys(table.getState().rowSelection).length;

  const updateDateRange = useCallback((range: DateRange) => {
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.set('created_at__gte', format(range.from, 'yyyy-MM-dd'));
      range.to && newSearchParameters.set('created_at__lte', format(range.to, 'yyyy-MM-dd'));
      return newSearchParameters;
    });
  }, [setSearchParameters]);

  const removeDateRange = useCallback(() => {
    setSearchParameters((previous) => {
      const newSearchParameters = new URLSearchParams(previous);
      newSearchParameters.delete('created_at__gte');
      newSearchParameters.delete('created_at__lte');
      return newSearchParameters;
    });
  }, [setSearchParameters]);

  const selectAllUsers = useCallback(() => {
    selectAllAvailable({
      limit: contactsList?.count,
      offset: 0,
      search: debounceSearch,
      district: searchParameters.get('district'),
      type: searchParameters.get('type'),
      created_at__gte: initialDateFrom,
      created_at__lte: initialDateTo,
    }, {
      onSuccess: (data) => {
        const selectedRows = data.results.reduce<Record<string, boolean>>((acc, item) => {
          acc[item.id] = true;
          return acc;
        }, {});
        table.setRowSelection(selectedRows);
        setSelectedContacts(data.results);
      },
      onError: (error) => {
        if (isClientError(error)) {
          showErrorToast('System message', 'Failed to select all contacts');
        }
      },
    });
  }, [selectAllAvailable, contactsList?.count, table, debounceSearch, searchParameters, initialDateFrom, initialDateTo]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex w-full items-center justify-between gap-4 border-b border-b-grey-200 px-6 py-5">
        <Heading tag="h2" variant="size-18" type="heading-600" className="leading-[25px]">
          Contacts
        </Heading>
        <DropdownRoot>
          <DropdownTrigger asChild className="[&>svg]:data-[state=open]:rotate-180">
            <Button type="button" variant="primary" iconRight={<PurpleIcon name="chevron-down" className="transition-transform" />}>Add Contacts</Button>
          </DropdownTrigger>
          <DropdownContent align="end" className="w-48">
            <DropdownItem iconName="user-add" onClick={openAddContactModal}>
              Add Contact
            </DropdownItem>
            <DropdownItem iconName="clipboard-copy" onClick={openBulkContactsImportModal}>
              Bulk Import
            </DropdownItem>
          </DropdownContent>
        </DropdownRoot>
      </div>
      <DataTable table={table} loading={isLoading}>
        <div className="flex flex-col gap-4 px-4 pb-6">
          <div className="flex flex-wrap items-center justify-between gap-2">
            <div className="flex items-center gap-4">
              <AppFilters config={filterConfig} loading={isFiltersLoading} />
              <DateRangePicker onUpdate={updateDateRange} align="start" triggerClassNames="h-10 w-64" onClear={removeDateRange} hasClearButton initialDateFrom={initialDateFrom} initialDateTo={initialDateTo} />
              <SearchInput
                value={search}
                onChange={onSearchChange}
                onClear={onClearSearch}
                placeholder="Search..."
                className="w-64"
              />
            </div>
            <div className="flex items-center gap-4">
              <Button variant="secondary" iconLeft={<PurpleIcon name="mail" />} onClick={() => toggleEmailModal(true)} disabled={currentSelectedRowsAmount === 0}>
                Send Email
              </Button>
              <PriorityListDropDown disabled={!isSameDistrict} createNewClick={() => toggleCreateListModal(true)} addToExistingClick={() => toggleAddToListModal(true)} />
              <PrintDropDown contactsRequestParameters={contactsRequestParams} />
              <DataTableViewOptions table={table} />
            </div>
          </div>
          <AppSelectedFiltersList config={filterConfig} />
          <div className="flex items-center gap-2">
            {currentSelectedRowsAmount > 0 && (
              <div className="flex items-center gap-2">
                <Text variant="size-14" type="body-500" className="text-brand-blue-700">
                  <span className="text-base font-semibold">{currentSelectedRowsAmount}</span>
                  {' '}
                  Contacts Selected
                </Text>
                <div className="flex items-center gap-3">
                  <Button variant="tertiary" className="h-auto rounded-full bg-brand-blue-100 px-1 py-0.5 text-brand-blue-700" size="small" onClick={clearAllSelected}>
                    Clear All
                  </Button>
                </div>
              </div>
            )}
            {currentSelectedRowsAmount !== contactsList?.count && !isLoading && (
              <Button variant="tertiary" iconLeft={isPending ? <PurpleIcon name="loader" className="animate-spin" /> : undefined} className="h-auto w-max rounded-full bg-brand-blue-100 px-1 py-0.5 text-brand-blue-700" size="small" onClick={selectAllUsers}>
                Select All Found (
                {contactsList?.count}
                )
              </Button>
            )}
          </div>
        </div>
      </DataTable>
      <AddContactModal />
      <BulkImportContactsModal />
      <ManagePriorityListDialog contacts={selectedContacts.map(({ id }) => id)} districtId={selectedDistrict} onSuccess={clearAllSelected} />
      <AddToExistingListDialog contacts={selectedContacts.map(({ id }) => id)} districtId={selectedDistrict} onSuccess={clearAllSelected} />
      <SendEmailDialog contacts={Object.keys(table.getState().rowSelection)} />
    </div>
  );
};
